import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  btn: {
    margin: 8,
    borderRadius: 8,
    background: 'rgba(255, 255, 255, 0.1)',
    color: grey[200],
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
  },
}));

export default function OpacityBtn(props) {
  const { classes } = useStyles();

  return <Button {...props} className={classes.btn} />;
}
