import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import capitalize from '../../helpers/capitalize';

const useStyles = makeStyles()(() => ({
  tab: {
    width: 150,
    minWidth: 150,
    color: grey[300],
    opacity: '75%',
    '&.Mui-selected': {
      opacity: '100%',
    },
  },
  smallTab: {
    width: 100,
    minWidth: 100,
  },
}));

export default function NavbarTabs({ navItems, handleChange, value }) {
  const { classes } = useStyles();

  const isMobileView = useMediaQuery('(max-width: 1200px)');

  return (
    <Tabs
      centered
      value={value}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="navbar tabs"
    >
      {navItems.map(navItem => (
        <Tooltip key={navItem.title} title={capitalize(navItem.title)}>
          <Tab
            className={isMobileView ? classes.smallTab : classes.tab}
            style={{ height: 65 }}
            icon={
              navItem.muiIcon || (
                <FontAwesomeIcon
                  style={{ width: 35, height: 35 }}
                  icon={navItem.icon}
                  // color={value === 0 ? grey[200] : undefined}
                />
              )
            }
            aria-label={navItem.title}
            tabIndex={0}
          />
        </Tooltip>
      ))}
    </Tabs>
  );
}
