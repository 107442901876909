import Markdown from 'markdown-to-jsx';
import ClarifyHotelSearch from './ClarifyHotelSearch';
import HotelResults from './HotelResults';
import dayjs from 'dayjs';
import ChatbotLoader from './ChatbotLoader';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

function functionCallToMessage(functionCall) {
  if (functionCall.name === 'findHotels')
    return <ClarifyHotelSearch {...JSON.parse(functionCall.arguments)} />;
  if (functionCall.name === 'hotelResults')
    return <HotelResults {...JSON.parse(functionCall.arguments)} />;
  // return <>{functionCall.arguments}</>;
  return (
    <>My apologies, I didn&apos;t catch that. Can you say it another way?</>
  );
}

export default function AssistantMessage({ message, isLoading }) {
  return (
    <div className="tw-mx-2">
      <div className="tw-font-semibold tw-text-sm">Travel Planner</div>
      <div className="tw-flex tw-justify-start">
        <div className="tw-py-3 tw-px-4 tw-bg-gray-200 tw-text-gray-900 dark:tw-bg-gray-800 dark:tw-text-white tw-rounded-br-xl tw-rounded-tr-xl tw-rounded-bl-xl tw-max-w-full">
          {message &&
            !message.content &&
            message.function_call &&
            functionCallToMessage(message.function_call)}
          {isLoading ? (
            <ChatbotLoader />
          ) : (
            <Markdown
              options={{
                overrides: {
                  a: {
                    component: props => (
                      <a
                        {...props}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        className="tw-text-primary-800 dark:tw-text-primary-400 tw-underline"
                      />
                    ),
                  },
                  img: {
                    component: props => {
                      return props.alt;
                    },
                  },
                  table: {
                    props: {
                      className: 'table-striped tw-w-100 tw-table-auto',
                    },
                  },
                  td: {
                    props: {
                      className: 'tw-p-2',
                    },
                  },
                  th: {
                    props: {
                      className: 'tw-p-2',
                    },
                  },
                },
              }}
            >
              {message.content}
            </Markdown>
          )}
        </div>
      </div>
      {!isLoading && (
        <div className="tw-text-xs tw-text-gray-600 dark:tw-text-gray-200">
          {dayjs(message.createdAt).fromNow()}
        </div>
      )}
    </div>
  );
}
