import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Link from '@mui/material/Link';
// eslint-disable-next-line import/no-cycle
import Header from '../Header/Header';
import AllTerms from './AllTerms';

const LoginDialog = props => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Header />
      <Dialog open={open} aria-labelledby="customized-dialog-title">
        <MuiDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          Terms of Service and Privacy
        </MuiDialogTitle>
        <MuiDialogContent>
          <p>
            <strong>
              We have updated our Terms of Service and Privacy Policy. Please
              read through them carefully.
            </strong>
          </p>
          <AllTerms />
        </MuiDialogContent>
        <MuiDialogActions>
          <Link color="textPrimary" href="/">
            <Button color="primary" onClick={() => setOpen(false)}>
              Decline
            </Button>
          </Link>
          <Button onClick={props.acceptTos} variant="contained" color="primary">
            Accept
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

LoginDialog.propTypes = {
  acceptTos: PropTypes.func.isRequired,
};

export default LoginDialog;
