/* eslint-disable react/no-unescaped-entities */
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';

const Privacy = () => {
  return (
    <div className="highlightLinks">
      <p>Last updated: July 4, 2024</p>

      <p>
        The TravelFeed and Truvvl websites and apps as well as TravelFeed
        Hosting are operated by CurlyCorn LLC. We are committed to protecting
        the privacy and personal information of our users. We understand that
        you entrust us with sensitive information, and we take our
        responsibility to safeguard this information very seriously.
      </p>
      <p>
        This privacy policy explains how we collect, use, and share personal
        information when you access and use our website, products, and services.
        It also explains your rights and choices when it comes to your personal
        information.
      </p>
      <p>
        By using our website, products, or services, you agree to the
        collection, use, and sharing of your personal information as described
        in this privacy policy.
      </p>
      <Typography gutterBottom variant="h5">
        Definitions
      </Typography>

      <b>TravelFeed</b>
      <p>
        This Privacy Policy describes how the TravelFeed website and app, the
        Truvvl app, and TravelFeed Hosting, collectively referred to as
        &quot;TravelFeed&quot; (or &quot;we&quot; or &quot;us&quot; or
        &quot;our&quot;) collects, uses, and handles your data when you use our
        website, products, app and services (&quot;services&quot;). Please take
        the time to carefully read through this policy. As you use our services,
        we want to be clear how TravelFeed uses your information and how your
        privacy is protected. By using the services, you consent to the data
        practices detailed in this policy.
      </p>

      <b>GDPR</b>
      <p>General Data Protection Regulation Act.</p>

      <b>Data Controller</b>
      <p>
        Data Controller means the natural or legal person who (either alone or
        jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal information are, or are to
        be, processed.
      </p>

      <b>Data Subject</b>
      <p>
        Data Subject is any living individual who is using our service and is
        the subject of personal pata.
      </p>

      <Typography gutterBottom variant="h5">
        1. Principles for Processing Personal Data
      </Typography>

      <p>Our principles for processing personal data are:</p>

      <ul>
        <li>
          <b>Fairness and lawfulness:</b> When we process personal data, the
          individual rights of the Data Subjects must be protected. All personal
          data must be collected and processed in a legal and fair manner.
        </li>
        <li>
          <b>Restricted to a specific purpose:</b> The personal data of Data
          Subject must be processed only for specific purposes.
        </li>
        <li>
          <b>Transparency:</b> The Data Subject must be informed of how his/her
          data is being collected, processed and used.
        </li>
      </ul>
      <Typography gutterBottom variant="h5">
        2. What Personal Data We Collect and Process
      </Typography>

      <p>
        TravelFeed collects several different types of personal data for various
        purposes. Personal pata may include, but is not limited to:
      </p>

      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>
          IP addresses and geographical locations used to access TravelFeed
        </li>
      </ul>

      <Typography gutterBottom variant="h5">
        3. How We Use the Personal Data
      </Typography>

      <p>TravelFeed uses the collected personal data for various purposes:</p>

      <ul>
        <li>To provide you with services</li>
        <li>To notify you about changes to our services and/or products</li>
        <li>To provide customer support</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          services
        </li>
        <li>To detect, prevent and address technical issues</li>
      </ul>

      <Typography gutterBottom variant="h5">
        4. Legal Basis for Collecting and Processing Personal Data
      </Typography>

      <p>
        TravelFeed legal basis for collecting and using the personal data
        described in this Data Protection Policy depends on the personal data we
        collect and the specific context in which we collect the information:
      </p>

      <ul>
        <li>TravelFeed needs to perform a contract with you</li>
        <li>You have given TravelFeed permission to do so</li>
        <li>
          Processing your personal data is in TravelFeed&apos;s legitimate
          interests
        </li>
        <li>TravelFeed needs to comply with the law</li>
      </ul>

      <Typography gutterBottom variant="h5">
        5. Retention of Personal Data
      </Typography>

      <p>
        TravelFeed will retain your personal information only for as long as is
        necessary for the purposes set out in this Data Protection Policy. If
        you choose to save personal information on third-party blockchains,
        these will be retained on the blockchain forever and cannot be deleted,
        but you may choose to edit them in order to hide them on TravelFeed.
      </p>

      <p>
        TravelFeed will retain and use your information to the extent necessary
        to comply with our legal obligations, resolve disputes, and enforce our
        policies.
      </p>

      <Typography gutterBottom variant="h5">
        6. Data Protection Rights
      </Typography>

      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what personal
        data we hold about you and if you want it to be removed from our
        systems, please contact us.
      </p>

      <p>
        In certain circumstances, you have the following data protection rights:
      </p>

      <ul>
        <li>
          The right to access, update or to delete the information we have on
          you
        </li>
        <li>The right of rectification</li>
        <li>The right to object</li>
        <li>The right of restriction</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>

      <Typography gutterBottom variant="h5">
        7. Information You Provide to Us
      </Typography>
      <p>
        We collect information you provide directly to us when you use our
        services. Depending on which service you use, we may collect different
        information about you.
      </p>
      <p>This includes:</p>
      <Typography gutterBottom variant="h6">
        7.1. Information Regarding Your Use of Our Services
      </Typography>
      <p>
        We collect the content and other information you provide when you use
        our services. This includes information used to create your account
        (e.g. a username, email address), account preferences, and the content
        of information you post to the platform (e.g. text, photos, videos,
        links).
      </p>
      <Typography gutterBottom variant="h6">
        7.2. Other Information
      </Typography>
      <p>
        You may choose to provide other information directly to us. For example,
        we may collect information when you fill out a form, participate in
        contests, sweepstakes or promotions, apply for a job, communicate with
        us via third-party sites and services, request customer support or
        otherwise communicate with us.
      </p>
      <Typography gutterBottom variant="h5">
        8. Information We Collect Automatically
      </Typography>
      <p>
        When you access or use our services, we may also automatically collect
        information about you.
      </p>
      <p>This includes:</p>
      <Typography gutterBottom variant="h6">
        8.1. Log and Usage Data
      </Typography>
      <p>
        We may log information when you access and use the services. This may
        include your IP address, user-agent string, browser type, operating
        system, referral URLs, device information (e.g. device IDs), pages
        visited, links clicked, user interactions (e.g. voting data), the
        requested URL, hardware settings, and search terms.
      </p>
      <Typography gutterBottom variant="h6">
        8.2. Information Collected from Cookies
      </Typography>
      <p>
        We may receive information from cookies, which are pieces of data your
        browser stores and sends back to us when making requests. We use this
        information to improve your experience, understand user activity, and
        improve the quality of our services. On how you can disable cookies,
        please see &quot;Your Choices&quot; below. For more information about
        cookies read our <Link href="/about/cookies">cookie policy</Link>.
      </p>

      <Typography gutterBottom variant="h6">
        8.3. Location Information
      </Typography>
      <p>
        With your consent, we may collect information about the specific
        location of your mobile device (for example, by using GPS). You can
        revoke this consent at any time by changing the preferences on your
        device, but doing so may affect your ability to use all of the features
        and functionality of our services.
      </p>
      <Typography gutterBottom variant="h6">
        8.4. Social Sharing
      </Typography>
      <p>
        We may offer social sharing features or other integrated tools that let
        you share content or actions you take on our services with other media.
        Your use of these features enables the sharing of certain information
        with your friends or the public, depending on the settings you establish
        with the third-party that provides the social sharing feature. For more
        information about the purpose and scope of data collection and
        processing in connection with social sharing features, please visit the
        privacy policies of the third parties that provide these social sharing
        features (e.g. Tumblr, Facebook, Reddit, Pinterest, and Twitter).
      </p>
      <Typography gutterBottom variant="h5">
        9. How We Use Information About You
      </Typography>
      <p>We use information about you to:</p>
      <ul>
        <li>Provide, maintain, and improve the services;</li>
        <li>
          Help protect the safety of TravelFeed and our users, which includes
          blocking suspected spammers, addressing abuse, and enforcing the
          TravelFeed user agreement and the Terms of Service;
        </li>
        <li>
          Send you technical notices, updates, security alerts and other support
          and administrative messages;
        </li>
        <li>Provide customer service;</li>
        <li>
          Communicate with you about products, services, offers, promotions, and
          events, and provide other news and information we think will be of
          interest to you (for information about how to opt out of these
          communications, see &quot;Your Choices&quot; below);
        </li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with
          our services;
        </li>
        <li>
          Personalize the services and provide advertisements, content and
          features that match user profiles or interests.
        </li>
      </ul>

      <Typography gutterBottom variant="h5">
        10. How We Share Information
      </Typography>
      <p>
        When you use the services, certain information may be shared with other
        users and the public. For example:
      </p>

      <p>
        When you submit a post or comment to the services, visitors to and users
        of our services will be able to see the content of your posts and
        comments, the username associated with your posts or comments, and the
        date and time you originally submitted the post or comment. Although
        some parts of the services may be private or quarantined, they may
        become public and you should take that into consideration before posting
        to the services.
      </p>
      <p>
        When other users view your profile, they will be able to see information
        about your activities on the services, such as your username, prior
        posts and comments and how long you have been a member of the service.
      </p>
      <p>
        Please note that while we offer an optional service to post to third
        party blockchains, deleting content from the blockchain is technically
        impossible, but you can edit your posts and delete the content to hide
        them on major frontends. If you want us to remove all your content on
        TravelFeed at once, you can write us an email and request to have your
        account removed. If you would like to make use of your right to data
        portability, please write us an email and we will send you all data that
        we have stored on you.
      </p>

      <p>
        We will not share, sell, or give away any of our users' personal
        information to third parties, unless one of the following circumstances
        applies:
      </p>
      <ul>
        <li>
          Except as it relates to advertisers and our ad partners, we may share
          information with vendors, consultants, and other service providers who
          need access to such information to carry out work for us;
        </li>
        <li>
          If you participate in contests, sweepstakes, promotions, special
          offers, or other events or activities in connection with our services,
          we may share information with entities that partner with us to provide
          these offerings;
        </li>
        <li>
          We may share information (and will attempt to provide you with prior
          notice, to the extent legally permissible) in response to a request
          for information if we believe disclosure is in accordance with, or
          required by, any applicable law, regulation, legal process or
          governmental request;
        </li>
        <li>
          We may share information in response to an emergency if we believe
          it&quot;s necessary to prevent imminent and serious bodily harm to a
          person;
        </li>
        <li>
          We may share information if we believe your actions are inconsistent
          with our user agreements, or other TravelFeed policies, or to protect
          the rights, property, and safety of ourselves and others;
        </li>
        <li>
          We may share information between and among TravelFeed, and its current
          and future parents, affiliates, subsidiaries, and other companies
          under common control and ownership; and
        </li>
        <li>
          We may share information with your consent or at your direction.
        </li>
      </ul>
      <p>
        We may share aggregated or de-identified information, which cannot
        reasonably be used to identify you.
      </p>

      <Typography gutterBottom variant="h5">
        11. Transfer Of Data
      </Typography>
      <p>
        Your information, including personal pata, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside of the EU and choose to provide information
        to us, please note that although our servers are located in the EU, we
        may transfer the data, including personal data, outside the EU and
        process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        TravelFeed will take all steps reasonably necessary to ensure that your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of your personal pata will take place to an organization or
        a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>

      <Typography gutterBottom variant="h5">
        12. Analytics Partners
      </Typography>
      <p>
        We use a self-hosted instance of{' '}
        <a
          href="https://plausible.io/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Plausible Analytics
        </a>{' '}
        to collect anonymous usage data about visitors to our website. Plausible
        Analytics is an open-source, privacy-friendly analytics tool that does
        not use cookies or collect any personal data. It only collects
        aggregated data such as page views, referral sources, and device types.
        The analytics data is stored on our own servers in the EU and is not
        shared with any third parties.
      </p>

      <p>
        For more information about how you may control the collection and / or
        use of information for advertising and analytics purposes, please see
        &quot;Your Choices.&quot;
      </p>

      <Typography gutterBottom variant="h5">
        13. Security
      </Typography>
      <p>
        We take reasonable measures to help protect information about you from
        loss, theft, misuse and unauthorized access, disclosure, alteration, and
        destruction.
      </p>
      <Typography gutterBottom variant="h5">
        14. Children under 18
      </Typography>
      <p>
        Although we welcome users from all walks of life, TravelFeed is not
        intended or directed at individuals under the age of 18. Therefore,
        individuals under the age of 18 may not create an account or otherwise
        access or use the services.
      </p>
      <Typography gutterBottom variant="h5">
        15. Your Choices
      </Typography>
      <p>
        As a TravelFeed user, you have choices about how to protect and limit
        the collection, use, and disclosure of, information about you. This
        includes:
      </p>
      <Typography gutterBottom variant="h6">
        15.1. Preferences
      </Typography>
      <p>
        We may provide you with tools and preference settings that allow you to
        access, correct, delete, and modify information associated with your
        account.
      </p>
      <Typography gutterBottom variant="h6">
        15.2. Deleting Accounts
      </Typography>
      <p>
        TravelFeed does store information when you sign up or activate our
        EasySignUp / EasyLogin services and also when logging in through
        third-party authentication options, but TravelFeed does not receive or
        store the unencrypted keys of supported third-party blockchains. To
        delete the connection with TravelFeed and a third-party authentication
        service, please see their help page on how to revoke the authentication
        of TravelFeed. Revoking the connection on a third-party authentication
        service will, however, not delete your TravelFeed account. In order to
        delete your Travelfeed account please send us an email. Please note,
        however, that deleting your TravelFeed account does not delete any
        posts, comments, votes or other transactions on third-party blockchains
        previously broadcasted through TravelFeed. We may also retain certain
        information about you as required by law or for legitimate business
        purposes after you delete your account. Any information that has been
        published to supported third-party blockchains will remain indefinitely.
      </p>
      <Typography gutterBottom variant="h6">
        15.3. Cookies
      </Typography>
      <p>
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove or reject first-
        and third-party cookies. Please note that if you choose to remove or
        reject cookies, this could affect the availability and functionality of
        our services. For more information about cookies read our{' '}
        <Link href="/about/cookies">cookie policy</Link> section.
      </p>
      <Typography gutterBottom variant="h6">
        15.4. Third-Party Advertising and Analytics
      </Typography>
      <p>
        Some analytics providers may provide specific opt-out mechanisms and we
        will provide, as needed, additional tools and third-party services that
        allow you to better understand cookies and how you can opt-out. If you
        are using the Truvvl app, you may opt out of Google Analytics data
        collection in the app settings.
      </p>
      <Typography gutterBottom variant="h6">
        15.5. Do Not Track
      </Typography>
      <p>
        Do Not Track (&quot;DNT&quot;) is a privacy preference that you can set
        in most web browsers. We support DNT in our services. There is no
        accepted standard on how to respond to web browsers&quot; DNT signals.
        When you have DNT enabled, we may still use information collected for
        analytics and measurement purposes or to otherwise provide our services,
        but we will not load any third-party trackers. You may, however, opt out
        of having information about you collected and used for behavioral
        advertising purposes, as described above.
      </p>
      <Typography gutterBottom variant="h6">
        15.6. Promotional Communications
      </Typography>
      <p>
        You may opt out of receiving promotional communications from us by
        following the instructions in those communications. If you opt out, we
        may still send you non-promotional communications, such as information
        about your account or your use of our services.
      </p>
      <Typography gutterBottom variant="h6">
        15.7. Notifications
      </Typography>
      <p>
        With your consent, we may send promotional and non-promotional push
        notifications or alerts to your mobile device or desktop browser. You
        can deactivate these messages at any time by changing the notification
        settings on your mobile device/ browser.
      </p>
      <Typography gutterBottom variant="h6">
        15.8. International Data Transfers
      </Typography>
      <p>
        By accessing or using the services or otherwise providing information to
        us, you consent to the processing, transfer and storage of information
        in and to applicable countries, where you may not have the same rights
        as you do under local law.
      </p>

      <Typography gutterBottom variant="h5">
        16. Service Providers
      </Typography>

      <p>
        We may employ third-party companies and individuals to facilitate our
        service (&quot;service Providers&quot;), to provide the service on our
        behalf, to perform service-related services or to assist us in analyzing
        how our service is used.
      </p>
      <p>
        These third parties have access to your personal pata only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>

      <p>We use the following service providers:</p>

      <p>
        <b>Login service providers</b>
      </p>
      <p>We allow login through the following third-party services: </p>

      <ul>
        <li>
          <a
            href="https://hivesigner.com/about"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Hivesigner
          </a>
        </li>
        <li>
          {' '}
          <a
            href="https://steemlogin.com/about"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Steemlogin
          </a>
        </li>
        <li>
          <a
            href="https://facebook.com"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            href="https://google.com"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Google
          </a>
        </li>
      </ul>

      <p>
        <b>Plausible Analytics</b>
      </p>
      <p>
        In order to track website usage statistics, we use a self-hosted
        instance of Plausible Analytics. The service collects aggregated data
        about website visits and other usage metrics. This data does not include
        any personal information, such as IP addresses. Plausible Analytics does
        not use cookies and does not store any personal data. For more
        information about how Plausible Analytics collects and uses data, please
        refer to the{' '}
        <a
          href="https://plausible.io/data-policy"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Plausible Data Policy
        </a>
        .
      </p>
      <p>
        <b>Cloudflare</b>
      </p>
      <p>
        We use the services of Cloudflare to accelerate our service. Cloudflare
        provides basic web analytics services that tracks and reports website
        traffic and determines the location of your IP address. Cloudflare uses
        the data collected to track and monitor the use of our service. For more
        information on the privacy practices of Cloudflare, please visit the{' '}
        <a
          href="https://www.cloudflare.com/privacypolicy/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Cloudflare Privacy &amp; Terms web page
        </a>
        .
      </p>
      <p>
        <b>Amazon Web Services</b>
      </p>
      <p>
        We use the services of AWS to for image storage and delivery. For more
        information please review the{' '}
        <a
          href="https://aws.amazon.com/privacy/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          AWS Privacy web page
        </a>
        .
      </p>
      <p>
        <b>Stripe</b>
      </p>
      <p>
        We use Stripe to process payments for our products and services. By
        using our website and making a purchase, you agree to the collection,
        use, and sharing of your personal and payment information as described
        in Stripe's{' '}
        <a
          href="https://stripe.com/legal"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://stripe.com/privacy"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          privacy policy
        </a>
        . We are not responsible for the security of your payment information,
        as this is handled by Stripe.
      </p>
      <p>
        <b>Mapbox and Google Maps</b>
      </p>
      <p>
        These two services are being used to provide our users with maps and
        geocoding to enhance the user experience on our website. For more
        information please visit the{' '}
        <a
          href="https://www.mapbox.com/privacy/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Mapbox privacy page
        </a>{' '}
        and the{' '}
        <a
          href="https://www.google.com/intl/en_es/help/terms_maps/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Google Maps terms page
        </a>
        .
      </p>

      <p>
        <b>Sentry</b>
      </p>
      <p>
        Sentry helps us to report and track bugs. Please visit{' '}
        <a
          href="https://sentry.io/privacy/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          their privacy policy
        </a>{' '}
        for more information.
      </p>

      <p>
        <b>Embedding Media</b>
      </p>
      <p>
        In order to provide a rich and interactive user experience, our website
        and products may include media that is embedded from third-party
        websites and services. Please be aware that we do not control the
        privacy practices of these embeds. You can find the individual privacy
        policies of these embeds by following the links provided. By accessing
        and using our website and products, you acknowledge and agree that we
        are not responsible for the privacy practices of these embeds.
      </p>

      <ul>
        <li>
          <a
            href="https://policies.google.com/privacy?hl=en"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            YouTube
          </a>
        </li>
        <li>
          <a
            href="https://threespeakvideo.b-cdn.net/static/terms_of_service.pdf"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            3speak
          </a>
        </li>
        <li>
          <a
            href="https://vimeo.com/privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Vimeo
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/policy.php"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            href="https://www.spotify.com/int/privacy/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Spotify
          </a>
        </li>
        <li>
          <a
            href="https://policies.google.com/privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Google Maps
          </a>
        </li>
        <li>
          <a
            href="https://privacycenter.instagram.com/policy"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Instagram
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/legal/page/us/privacy-policy/en"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            TikTok
          </a>
        </li>
        <li>
          <a
            href="https://soundcloud.com/pages/privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Soundcloud
          </a>
        </li>
        <li>
          <a
            href="https://www.twitch.tv/p/legal/privacy-policy/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Twitch
          </a>
        </li>
        <li>
          <a
            href="https://steemit.com/privacy.html"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Steemitimages
          </a>
        </li>
        <li>
          <a
            href="https://hive.blog/privacy.html"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Hiveimages
          </a>
        </li>
        <li>
          <a
            href="https://unsplash.com/privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Unsplash
          </a>
        </li>
        <li>
          <a
            href="https://mapa-turystyczna.pl/polityka-prywatnosci"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            mapa turystyczna
          </a>
        </li>
      </ul>

      <p>
        Please click on the names for these services for more information on
        their privacy policy or about page.
      </p>

      <Typography gutterBottom variant="h5">
        17. Links To Other Sites
      </Typography>
      <p>
        Our service may contain links to other sites that are not operated by
        us. If you click on a third-party link, you will be directed to that
        third-party&#39;s site. We strongly advise you to review the Privacy
        Policy of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third-party sites or services.
      </p>

      <Typography gutterBottom variant="h5">
        18. Content Deletion
      </Typography>

      <p>
        We store the majority of content of the Data Subject on our own servers
        and databases. A copy of all content as well as the permanent deletion
        of this content can be requested at any time by email or on our Discord.
      </p>

      <p>
        We also offer an optional service to push content to supported
        third-party blockchains. TravelFeed has no ability to remove content
        from supported third-party blockchains or affect access by any other
        third-party to any content stored on these blockchains. The reason being
        that transactions sent to these blockchain are leaving the control of
        TravelFeed and therefore TravelFeed has no ability to modify or delete
        what is on mentioned supported third-party blockchains. Additionally, no
        third-party has the technical or effectual ability to modify or delete
        content found on blockchains, but everyone has the ability to display
        the content in question.
      </p>
      <Typography gutterBottom variant="h5">
        19. Changes
      </Typography>
      <p>
        We may change this Privacy Policy from time to time. If we do, we will
        let you know by revising the date at the top of the policy and by asking
        you to agree to the changes upon your next login. We encourage you to
        review the Privacy Policy whenever you access or use our services or
        otherwise interact with us to stay informed about our information
        practices and the ways you can help protect your privacy. If you
        continue to use our services after Privacy Policy changes go into
        effect, you consent to the revised policy.
      </p>
      <Typography gutterBottom variant="h5">
        20. Contact Us
      </Typography>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>
          By email:{' '}
          <a
            href="mailto:contact@travelfeed.com"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            contact@travelfeed.com
          </a>
        </li>
        <li>
          By visiting our Discord:{' '}
          <a
            href="https://discord.gg/jWWu73H"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            https://discord.gg/jWWu73H
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
