import { gql } from '@apollo/client';

export const ACCEPT_TOS = gql`
  mutation acceptTos($token: String!) {
    acceptTos(token: $token) {
      name
      success
    }
  }
`;

export const CONFIRM_EMAIL_SIGN_UP = gql`
  mutation confirmEmailSignUp($token: String!) {
    confirmEmailSignUp(token: $token) {
      success
      message
    }
  }
`;

export const GET_LOGIN_TOKEN = gql`
  mutation login(
    $token: String
    $app: String
    $usernameOrEmail: String
    $password: String
  ) {
    login(
      token: $token
      app: $app
      usernameOrEmail: $usernameOrEmail
      password: $password
    ) {
      name
      hasAcceptedTos
      success
      isNewUser
      signUpToken
      profileImg
      needsEmailConfirmation
      message
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($signUp: SignUpInput!) {
    signUp(signUp: $signUp) {
      success
      needsEmailConfirmation
      message
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation revokeToken {
    revokeToken {
      success
      message
    }
  }
`;
