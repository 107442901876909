import Avatar from '@mui/material/Avatar';
import { teal } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { DynamicFeed } from '@mui/icons-material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DiscoverIcon from '@mui/icons-material/Explore';
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
// import ContestIcon from '@mui/icons-material/RateReview';
import NewIcon from '@mui/icons-material/Restore';
import FeaturedIcon from '@mui/icons-material/Star';
import React, { useEffect, useState } from 'react';
import capitalize from '../../helpers/capitalize';
import { getUser } from '../../helpers/token';
import Link from '@mui/material/Link';
import Logout from '../Login/LogoutButton';
// eslint-disable-next-line import/no-cycle
import LoginButton from './LoginButton';

const useStyles = makeStyles()(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: teal[600],
    padding: theme.spacing(3, 2),
  },
  avatar: {
    width: 50,
    height: 50,
  },
  typography: {
    fontWeight: 500,
  },
}));

export default function MenuDrawer(props) {
  const [user, setUser] = useState(undefined);
  const { active, drawerItems } = props;

  useEffect(() => {
    setUser(getUser());
  }, []);

  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = state => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(state);
  };

  const feedList = [
    {
      label: 'featured',
      link: '/featured',
      icon: <FeaturedIcon />,
    },
    {
      label: 'new',
      link: '/created',
      icon: <NewIcon />,
    },
    {
      label: 'discover',
      link: '/discover',
      icon: <DiscoverIcon />,
    },
    {
      label: 'feed',
      link: '/feed',
      icon: <DynamicFeed />,
    },
  ];

  const pagesList = [
    // {
    //   label: 'contests',
    //   link: '/contests',
    //   icon: <ContestIcon />,
    // },
    {
      label: 'destinations',
      link: '/destinations',
      icon: <DiscoverIcon />,
    },
    { label: 'map', link: '/map', icon: <MapIcon /> },
  ];

  if (user) {
    pagesList.push({
      label: 'bookmarks',
      link: '/bookmarks',
      icon: <BookmarkIcon />,
    });
  }

  const sideList = () => (
    <>
      <List>
        {feedList.map(el => (
          <Link color="textPrimary" href={el.link} key={el.label}>
            <ListItem selected={active === el.label} button key={el.label}>
              <ListItemIcon>{el.icon}</ListItemIcon>
              <ListItemText primary={capitalize(el.label)} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        {pagesList.map(el => (
          <Link color="textPrimary" href={el.link} key={el.link}>
            <ListItem selected={active === el.label} button key={el.label}>
              <ListItemIcon>{el.icon}</ListItemIcon>
              <ListItemText primary={capitalize(el.label)} />
            </ListItem>
          </Link>
        ))}
      </List>
    </>
  );

  return (
    <>
      {!props.hidden && (
        <span className="text-light">
          <IconButton
            color="inherit"
            onClick={toggleDrawer(true)}
            edge="start"
            aria-label="Open Drawer"
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </span>
      )}
      <SwipeableDrawer
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={classes.list} role="presentation">
          {(user && (
            <>
              <Paper square className={classes.paper}>
                <Link color="textPrimary" href={`/@${user}`}>
                  <Avatar
                    className={`cpointer ${classes.avatar}`}
                    src={`/avatar/${user}/small`}
                  />
                  <div className="text-light">
                    <Typography
                      variant="h6"
                      className={`pl-1 pt-2 ${classes.typography}`}
                      color="inherit"
                    >
                      {user}
                    </Typography>
                  </div>
                </Link>
              </Paper>
            </>
          )) || (
            <LoginButton
              isList
              isMenu
              onClickOpen={() => {}}
              onClickClose={() => {}}
            />
          )}
          <Divider />
          {drawerItems || sideList()}
          <Divider />
          {user && (
            <List>
              <Logout listItem handleLogout={props.handleLogout} />
            </List>
          )}
        </div>
      </SwipeableDrawer>
    </>
  );
}
