import { getUser } from '../../helpers/token';
import Link from '@mui/material/Link';
import ProfileAvatar from '../Profile/ProfileAvatar';
// eslint-disable-next-line import/no-cycle
import AccountMenu from './AccountMenu';
import CreateMenu from './CreateMenu';
import NotificationsMenu from './NotificationsMenu';
import RoundNavIconBtn from './RoundNavIconBtn';

// eslint-disable-next-line no-unused-vars
export default function RoundNavIcons({ handleLogout, reducedBtns }) {
  return (
    <>
      <div className="avatar-nav">
        <Link href={`/@${getUser()}`}>
          <RoundNavIconBtn style={{ padding: 0 }}>
            <ProfileAvatar author={getUser()} size={40} />
          </RoundNavIconBtn>
        </Link>
      </div>
      <style jsx>{`
        .avatar-nav {
          display: none;
        }
        @media (min-width: 1300px) {
          .avatar-nav {
            display: inline;
          }
        }
      `}</style>
      <div
        className={
          reducedBtns === 2
            ? 'd-xl-inline d-lg-inline d-md-inline d-sm-none d-none'
            : 'd-inline'
        }
      >
        <NotificationsMenu />
      </div>
      <span className="createmenu">
        <CreateMenu />
      </span>
      <AccountMenu handleLogout={handleLogout} />
      <style jsx global>{`
        @media (max-width: 348px) {
          .createmenu {
            display: none;
          }
        }
      `}</style>
    </>
  );
}
