import Card from '@mui/material/Card';
import { useRef, useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import shadow from 'helpers/shadow';
import Button from '@mui/material/Button';
import ChatMessage from './ChatMessage';
import AssistantMessage from './AssistantMessage';
import QuestionSuggestion from './QuestionSuggestion';
import useWindowSize from 'react-use/lib/useWindowSize';
import SuggestionButton from './SuggestionButton';

export default function Chatbot({
  setOpen,
  messages,
  input,
  handleInputChange,
  handleSubmit,
  isLoading,
  open,
  location,
  append,
  functionCall,
  msgLength,
  setMessages,
  isLanding,
}) {
  const formRef = useRef(null);
  const chatboxRef = useRef(null);

  const [inputHeight, setInputHeight] = useState(44);

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [messages, functionCall]);

  const { width, height } = useWindowSize();

  const useStyles = makeStyles()(() => ({
    card: {
      borderRadius: 16,
      boxShadow: shadow.xl,
      transition: 'height 0.3s ease',
      height: 0,
      width: 0,
    },
    cardOpen: {
      borderRadius: 16,
      boxShadow: shadow.xl,
      transition: 'height 0.3s ease',
      height: '38.25rem',
      width: '24rem',
    },
  }));

  const handleKeyDown = event => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.nativeEvent.isComposing
    ) {
      formRef.current?.requestSubmit();
      event.preventDefault();
    }
  };

  function handleRowChange(height) {
    setInputHeight(height);
  }

  const { classes } = useStyles();

  let chatRecommendations = [
    { message: 'Contact us' },
    { message: 'How to start a travel blog' },
  ];
  if (location)
    chatRecommendations = [
      { message: `Things to do in ${location}`, label: 'Things to do' },
      { message: `Sights in ${location}`, label: 'Sights' },
      { message: `${location} itinerary` },
      { message: `${location} blogs`, label: 'Travel blogs' },
      { message: `Flights to ${location}` },
      { message: `${location} hotels`, label: 'Hotels' },
    ];
  if (isLanding) {
    chatRecommendations = [
      { message: 'Things to do' },
      { message: 'Sights' },
      { message: `Itineraries` },
      { message: 'Travel blogs' },
      { message: `Flights` },
      { message: 'Hotels' },
    ];
  }

  return (
    <>
      <div className="tw-flex">
        <div className="tw-m-auto tw-text-gray-900">
          <Card
            className={open ? classes.cardOpen : classes.card}
            style={
              width <= 991
                ? {
                    height: open ? height : 0,
                    width: open ? width : 0,
                    borderRadius: 0,
                  }
                : undefined
            }
          >
            <div className="tw-flex tw-flex-wrap tw-h-full">
              <div className="tw-grow tw-bg-primary-500 dark:tw-bg-primary-700 tw-text-white tw-p-2 tw-sticky tw-w-full tw-h-12">
                <div className="tw-flex">
                  <div className="m-auto">Travel Planner</div>
                  <IconButton
                    onClick={() => setOpen(false)}
                    aria-label="Close"
                    size="small"
                    className="tw-text-white"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div
                className="tw-flex tw-flex-col lg:tw-h-128 tw-overflow-y-auto tw-pt-2 tw-w-full"
                ref={chatboxRef}
                style={
                  width <= 991
                    ? { height: height - inputHeight - 50 }
                    : undefined
                }
              >
                <div className="tw-grow sm:tw-grow-0" />
                <AssistantMessage
                  message={{
                    content: isLanding
                      ? 'I&apos;m here to help make your trip awesome. Where do you want to travel? 😀'
                      : `I'm here to help make your trip awesome. How can I help? 😀`,
                  }}
                />
                {messages.map(m => (
                  <ChatMessage message={m} key={m.id} />
                ))}
                {isLoading &&
                  messages.length > 0 &&
                  messages[messages.length - 1].role !== 'assistant' && (
                    <AssistantMessage isLoading />
                  )}
                {!isLoading && messages.length === 0 && (
                  <div className="tw-flex tw-flex-wrap tw-pb-2">
                    {chatRecommendations.map(recommendation => (
                      <QuestionSuggestion
                        recommendation={recommendation}
                        key={recommendation.message}
                        append={append}
                      />
                    ))}
                  </div>
                )}
                <SuggestionButton
                  functionCall={functionCall}
                  msgLength={msgLength}
                  messages={messages}
                  setMessages={setMessages}
                />
              </div>
              <div className="tw-w-full tw-mt-2 tw-sticky tw-bottom-0 tw-left-0">
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="tw-flex tw-text-lg"
                >
                  <Textarea
                    onHeightChange={handleRowChange}
                    rows={1}
                    maxRows={3}
                    value={input}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Ask something..."
                    className="tw-resize-none tw-w-full tw-py-2 tw-px-4 tw-outline-none tw-border-none tw-placeholder-gray-400 tw-bg-gray-100 dark:tw-bg-gray-900 dark:tw-text-white"
                    onKeyDown={handleKeyDown}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!input}
                    className="disabled:tw-bg-gray-400 dark:disabled:tw-bg-gray-700 tw-rounded-none tw-p-2 tw-text-white tw-border-none"
                    type="submit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-w-6 tw-h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                      />
                    </svg>
                  </Button>
                </form>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <style jsx global>{`
        .typing {
          align-items: center;
          display: flex;
          height: 17px;
        }
        .typing .dot {
          animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
          background-color: #6cad96; //rgba(20,105,69,.7);
          border-radius: 50%;
          height: 7px;
          margin-right: 4px;
          vertical-align: middle;
          width: 7px;
          display: inline-block;
        }
        .typing .dot:nth-child(1) {
          animation-delay: 200ms;
        }
        .typing .dot:nth-child(2) {
          animation-delay: 300ms;
        }
        .typing .dot:nth-child(3) {
          animation-delay: 400ms;
        }
        .typing .dot:last-child {
          margin-right: 0;
        }

        @keyframes mercuryTypingAnimation {
          0% {
            transform: translateY(0px);
            background-color: #6cad96; // rgba(20,105,69,.7);
          }
          28% {
            transform: translateY(-7px);
            background-color: #9ecab9; //rgba(20,105,69,.4);
          }
          44% {
            transform: translateY(0px);
            background-color: #b5d9cb; //rgba(20,105,69,.2);
          }
        }
      `}</style>
    </>
  );
}
