import { gql } from '@apollo/client';

export const ADD_BOOKMARK = gql`
  mutation addBookmark($author: String!, $permlink: String!) {
    addBookmark(author: $author, permlink: $permlink) {
      success
      message
    }
  }
`;

export const DELETE_BOOKMARK = gql`
  mutation deleteBookmark($author: String!, $permlink: String!) {
    deleteBookmark(author: $author, permlink: $permlink) {
      success
      message
    }
  }
`;

export const IS_BOOKMARKED = gql`
  query isBookmarked($author: String!, $permlink: String!) {
    isBookmarked(author: $author, permlink: $permlink)
  }
`;
