// import { faFacebook } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookie from 'js-cookie';
import { nanoid } from 'nanoid';
import Image from 'components/Image';
import React, {
  useEffect,
  useState,
  // useContext
} from 'react';
import {
  // FACEBOOK_APP_ID,
  GOOGLE_OAUTH_CLIENT,
  ROOTURL,
} from '../../config';
import GoogleLogo from '../../images/google.svg';
import HiveAuthLogo from '../../images/HiveAuth_logo.png';
import HiveSignerLogo from '../../images/hivesigner.svg';
// import SteemLogo from 'images/steem-logo.svg';
// import SteemLogoLight from 'images/steem.svg';
import LoginServiceButton from './LoginServiceButton';
// import UserContext from '../General/UserContext';
import HiveAuthButton from './HiveAuthButton';

const LoginServices = ({ isLogin }) => {
  const [csrfState, setCsrfState] = useState(undefined);

  // const { theme } = useContext(UserContext);
  // const useDarkMode = theme === 'dark';

  const hive = {
    name: 'Hivesigner',
    logo: (
      <Image
        src={HiveSignerLogo}
        alt="Hivesigner Logo"
        height={24}
        width={24}
      />
    ),
    link: `https://hivesigner.com/login-request/travelfeed.app?redirect_uri=${ROOTURL}%2Flogin%2Fhive&scope=posting&state=${csrfState}`,
  };
  let services = [
    hive,
    {
      name: 'Google',
      logo: <Image src={GoogleLogo} alt="Google Logo" height={24} width={26} />,
      link: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_OAUTH_CLIENT}&response_type=code&redirect_uri=${ROOTURL}%2Flogin%2Fgoogle&scope=email profile&state=${csrfState}`,
    },
  ];
  // if (isLogin)
  // services.push({
  //   name: 'Facebook',
  //   logo: (
  //     <FontAwesomeIcon
  //       icon={faFacebook}
  //       className="tw-h-6 tw-w-6 tw-text-[#0066ff]"
  //     />
  //   ),
  //   link: `https://www.facebook.com/v6.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${ROOTURL}/login/fb&state=${csrfState}`,
  // });
  // if (isLogin)
  //   services.push({
  //     name: 'Steem',
  //     logo: (
  //       <Image
  //         src={useDarkMode ? SteemLogoLight : SteemLogo}
  //         alt="Steem logo"
  //         height={24}
  //         width={26}
  //       />
  //     ),
  //     link: `https://steemlogin.com/login-request/travelfeed.app?redirect_uri=${
  //       ROOTURL === 'https://travelfeed.com' ? 'https://travelfeed.io' : ROOTURL
  //     }%2Flogin%2Fsteem&scope=posting&state=${csrfState}`,
  //   });

  useEffect(() => {
    const existingCookie = Cookie.get('csrf_state');
    if (existingCookie) {
      setCsrfState(existingCookie);
    } else {
      const state = nanoid();
      Cookie.set('csrf_state', state);
      setCsrfState(state);
    }
  }, []);

  return (
    <>
      <div className="tw-space-y-2">
        <div>
          <HiveAuthButton
            isLogin={isLogin}
            name="HiveAuth"
            logo={
              <Image
                src={HiveAuthLogo}
                alt="HiveAuth Logo"
                height={30}
                width={30}
              />
            }
          />
        </div>
        {services.map(service => (
          <div key={service.name}>
            <LoginServiceButton {...service} isLogin={isLogin} />
          </div>
        ))}
      </div>
    </>
  );
};

export default LoginServices;
