import { gql } from '@apollo/client';

export const VOTE = gql`
  mutation vote($author: String!, $permlink: String!, $weight: Int!) {
    vote(author: $author, permlink: $permlink, weight: $weight) {
      success
      message
    }
  }
`;

export const POST = gql`
  mutation post($post: PostInput!) {
    post(post: $post) {
      success
      message
    }
  }
`;

export const ACCOUNT_UPDATE = gql`
  mutation accountUpdate($profile: AccountUpdateInput!) {
    accountUpdate(profile: $profile) {
      success
      message
    }
  }
`;

export const FOLLOW = gql`
  mutation follow($author: String, $community: Int, $isFollowed: Boolean!) {
    follow(author: $author, community: $community, isFollowed: $isFollowed) {
      success
      message
    }
  }
`;
