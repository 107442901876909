import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Switch from '@mui/material/Switch';
import { Help, KeyboardArrowDown, Settings } from '@mui/icons-material';
import React, { useContext } from 'react';
import { getUser } from '../../helpers/token';
import Link from '@mui/material/Link';
import UserContext from '../General/UserContext';
import LogoutButton from '../Login/LogoutButton';
import ProfileAvatar from '../Profile/ProfileAvatar';
import LegalNotice from '../Sidebar/LegalNotice';
// eslint-disable-next-line import/no-cycle
import LoginButton from './LoginButton';
import PopupPaper from './PopupPaper';
import RoundNavIconBtn from './RoundNavIconBtn';

export default function AccountMenu({ handleLogout }) {
  const { theme, setDarkMode, setLightMode } = useContext(UserContext);

  const useDarkMode = theme === 'dark';

  const handleDarkModeToggle = () => {
    if (useDarkMode) setLightMode();
    else setDarkMode();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickAwayListener, setClickAwayListener] = React.useState(true);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const user = getUser();

  return (
    <>
      <RoundNavIconBtn onClick={handleClick} aria-label="More">
        <KeyboardArrowDown />
      </RoundNavIconBtn>
      <Popper id={id} open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <PopupPaper>
                <ClickAwayListener
                  onClickAway={handleClose}
                  mouseEvent={clickAwayListener ? 'onClick' : false}
                >
                  <MenuList className="tw-text-left">
                    {(user && (
                      <div className="d-xl-none d-lg-block d-md-block d-sm-block d-block">
                        <Link color="textPrimary" href={`/@${user}`}>
                          <MenuItem>
                            <ListItemIcon>
                              <ProfileAvatar author={user} size={25} />
                            </ListItemIcon>
                            <ListItemText primary="Your Profile" />
                          </MenuItem>
                        </Link>
                        <Divider />
                      </div>
                    )) || (
                      <>
                        <div className="d-xl-none d-lg-block d-md-block d-sm-block d-block">
                          <LoginButton
                            isMenu
                            onClickOpen={() => setClickAwayListener(false)}
                            onClickClose={() => setClickAwayListener(true)}
                          />
                          <Divider />
                        </div>
                      </>
                    )}
                    {user && (
                      <Link color="textPrimary" href="/dashboard/settings">
                        <MenuItem>
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <ListItemText primary="Settings" />
                        </MenuItem>
                      </Link>
                    )}
                    <Link color="textPrimary" href="/help">
                      <MenuItem>
                        <ListItemIcon>
                          <Help />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={handleDarkModeToggle}>
                      <ListItemIcon>
                        <FontAwesomeIcon
                          style={{ width: 20, height: 20 }}
                          icon={faMoon}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Night Mode" />
                      <Switch
                        checked={useDarkMode}
                        onChange={handleDarkModeToggle}
                        // onInput={changeSettings}
                        // For now, dark mode is saved on the device only
                        value="useDarkMode"
                        color="primary"
                      />
                    </MenuItem>
                    {user && (
                      <>
                        <Divider />
                        <LogoutButton handleLogout={handleLogout} />
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
                <div style={{ maxWidth: 220 }} className="text-left">
                  <LegalNotice />
                </div>
              </PopupPaper>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}
