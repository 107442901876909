/* eslint-disable import/no-cycle */
import {
  faCompass,
  faHome,
  faMap,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import AppBar from '@mui/material/AppBar';
import { grey, teal } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { supportsPassiveEvents } from 'detect-it';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { getUser } from '../../helpers/token';
import Link from '@mui/material/Link';
import BottomNavigation from '../Dashboard/BottomNavigation';
import DestinationsNav from '../Destinations/DestinationsNav';
import BookmarkIcon from '../Post/BookmarkIcon';
import FollowButton from '../Profile/FollowButton';
import AccountMenu from './AccountMenu';
import BackButton from './BackButton';
import HeaderGeocoder from './HeaderGeocoder';
import LoginButton from './LoginButton';
import MenuDrawer from './MenuDrawer';
import MobileGeocoderButton from './MobileGeocoderButton';
import NavbarTabs from './NavbarTabs';
import RoundNavIcons from './RoundNavIcons';
import ShareButton from './ShareButton';
import ChatbotButton from 'components/Chatbot/ChatbotButton';
import Image from 'next/image';
import { useRouter } from 'next/router';

const useStyles = makeStyles()(() => ({
  transparentNavBar: {
    boxShadow: 'none',
    background: 'transparent',
    transition: 'background 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
  },
  scrollingNavBar: {
    boxShadow: 'none',
    background: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))',
    transition: 'background 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
  },
  ssrNavBar: {
    background: teal[800],
    boxShadow: 'none',
    transition: 'background 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
  },
  root: {
    background: teal[800],
    transition: 'background 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
  },
  heading: {
    fontWeight: 'bold',
    color: grey[200],
    margin: 'auto',
    paddingLeft: 5,
  },
  whitebutton: {
    color: grey[200],
  },
}));

export default function Header(props) {
  const { classes } = useStyles();

  const router = useRouter();

  const {
    active,
    isScrollingNav,
    isSsr,
    isTransparentNavBar,
    drawerItems,
    drawerBreakSize,
    hideSearch,
    noChatbot,
  } = props;

  const [user, setUser] = useState();
  const [scrollingNav, setScrollingNav] = useState(isScrollingNav);
  const [ssrNav, setSsrNav] = useState(isSsr);
  const [navItems, setNavItems] = useState([]);

  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setScrollingNav(false);
    } else {
      setScrollingNav(true);
    }
  };

  useEffect(() => {
    setScrollingNav(props.isScrollingNav);
    setSsrNav(props.isSsr);
    setUser(getUser() || null);
    const newNavItems = [
      {
        icon: faHome,
        title: 'feed',
      },
      {
        icon: faMap,
        title: 'map',
      },
      {
        icon: faCompass,
        title: 'destinations',
      },
    ];
    if (getUser())
      newNavItems.push({
        muiIcon: <DashboardIcon fontSize="large" />,
        muiIconMobile: (
          <span
            style={{
              fontSize: 35,
              height: 30,
              width: 30,
              marginTop: -14,
              marginBottom: 14,
            }}
          >
            <DashboardIcon fontSize="inherit" />{' '}
          </span>
        ),
        title: 'dashboard',
      });
    else
      newNavItems.push({
        icon: faMobile,
        title: 'stories',
      });

    setNavItems(newNavItems);
    if (!props.isScrollingNav) return;
    window.addEventListener(
      'scroll',
      listenScrollEvent,
      // better scroll performance: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
      supportsPassiveEvents ? { passive: true } : false,
    );
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, [props]);

  const handleLogout = () => {
    setUser(undefined);
  };

  const isMobileView = useMediaQuery('(max-width: 991px)');

  let reducedBtns = 0;
  if (active === 'post') reducedBtns = 2;
  else if (active === 'blog' || active === 'community') reducedBtns = 1;

  let appBarClass = scrollingNav ? classes.scrollingNavBar : classes.root;
  if (ssrNav) appBarClass = classes.ssrNavBar;
  if (scrollingNav && isTransparentNavBar)
    appBarClass = classes.transparentNavBar;

  // Tab navigation
  let initialTab = false;
  if (active === 'featured') initialTab = 0;
  else if (active === 'map') initialTab = 1;
  else if (active === 'destinations') initialTab = 2;
  else if (active === 'dashboard' || active === 'stories') initialTab = 3;

  const [value, setValue] = useState(initialTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0)
      Router.push({
        pathname: '/featured',
      });
    else if (newValue === 1)
      Router.push({
        pathname: '/map',
      });
    else if (newValue === 2)
      Router.push({
        pathname: '/destinations',
      });
    else if (newValue === 3)
      Router.push({
        pathname: `/${navItems[3].title}`,
      });
  };

  let drawerBreak = `d-lg-none d-md-none`;
  if (drawerBreakSize === 'lg') drawerBreak = `d-lg-flex d-md-flex`;

  const handleRightClick = event => {
    event.preventDefault(); // Prevents default context menu
    router.push('/about/brand');
  };

  return (
    <>
      <div style={{ paddingTop: '65px' }} />
      <div style={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="primary" className={appBarClass}>
          <div className="container-fluid" style={{ height: '65px' }}>
            <div className="row h-100">
              <div className="col-xl-3 col-lg-2 col-md-8 col-sm-6 col-5">
                <div className="h-100 d-flex flex-row">
                  <div
                    className={`align-self-center d-xl-none ${drawerBreak} d-sm-flex d-flex`}
                  >
                    {([
                      'post',
                      'blog',
                      'destination',
                      'location',
                      'tag',
                      'page',
                      'integration',
                    ].indexOf(active) !== -1 && (
                      <>
                        <BackButton />
                        <MenuDrawer
                          drawerItems={drawerItems}
                          hidden
                          active={active}
                          handleLogout={handleLogout}
                        />
                      </>
                    )) || (
                      <MenuDrawer
                        drawerItems={drawerItems}
                        active={active}
                        handleLogout={handleLogout}
                      />
                    )}
                  </div>
                  <div className="tw-self-center">
                    <Link color="textPrimary" href={user ? '/featured' : '/'}>
                      <div className="tw-flex tw-h-12">
                        <Image
                          onContextMenu={handleRightClick}
                          priority
                          alt="TravelFeed"
                          src="/header-logo.svg"
                          width={1484.61}
                          height={256.49}
                          className="tw-w-44 tw-h-12 tw-hidden sm:tw-block tw-m-auto"
                        />
                        <Image
                          priority
                          alt="TravelFeed Logo"
                          src="/logo.svg"
                          width={1484.6}
                          height={256.49}
                          className="tw-h-10 tw-w-10 tw-block sm:tw-hidden tw-m-auto"
                        />
                      </div>
                    </Link>
                  </div>
                  {hideSearch ? <></> : <HeaderGeocoder />}
                </div>
              </div>
              {user !== undefined && (
                <>
                  <div className="col-xl-6 col-lg-7 d-xl-block d-lg-block d-md-none d-sm-none d-none my-auto text-center">
                    {(user && (
                      <NavbarTabs
                        navItems={navItems}
                        handleChange={handleChange}
                        value={value}
                      />
                    )) || (
                      <>
                        <div className="d-xl-block d-lg-none d-md-none d-sm-none d-none">
                          <DestinationsNav />
                        </div>
                        <div className="d-xl-none d-lg-block d-md-none d-sm-none d-none">
                          <DestinationsNav isCut />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="my-auto col-xl-3 col-lg-3 col-md-4 col-sm-6 col-7 text-right pr-1">
                    {active === 'post' && (
                      <>
                        <div className="d-xl-none d-lg-none d-md-none d-sm-inline d-inline">
                          <BookmarkIcon
                            isHeader
                            author={props.socialShare.author}
                            permlink={props.socialShare.permlink}
                          />
                        </div>
                        <div className="d-xl-none d-lg-none d-md-none d-sm-inline d-inline">
                          <ShareButton socialShare={props.socialShare} />
                        </div>
                      </>
                    )}
                    {active === 'blog' && (
                      <div className="d-xl-none d-lg-none d-md-none d-sm-inline d-inline">
                        <FollowButton author={props.author} btnstyle="icon" />
                      </div>
                    )}
                    {active === 'community' && (
                      <div className="d-xl-none d-lg-none d-md-none d-sm-inline d-inline">
                        <FollowButton
                          community={props.community}
                          btnstyle="icon"
                        />
                      </div>
                    )}
                    {(user && (
                      <>
                        <MobileGeocoderButton
                          reducedBtns={user ? reducedBtns : undefined}
                        />
                        <RoundNavIcons
                          handleLogout={handleLogout}
                          reducedBtns={reducedBtns}
                        />
                      </>
                    )) || (
                      <>
                        <div className="d-xl-inline d-lg-none d-md-none d-sm-none d-none text-right">
                          <LoginButton />
                        </div>
                        <div className="d-xl-none d-lg-inline d-md-inline d-sm-none d-none text-right">
                          <LoginButton onlySignUp />
                        </div>
                        <MobileGeocoderButton
                          reducedBtns={user ? reducedBtns : undefined}
                        />
                        <AccountMenu handleLogout={handleLogout} />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </AppBar>
      </div>
      {isMobileView && (
        <BottomNavigation
          navItems={navItems}
          handleChange={handleChange}
          value={value}
        />
      )}
      {!noChatbot && <ChatbotButton />}
    </>
  );
}
