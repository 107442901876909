import { useEffect, useState } from 'react';
import ChatbotLoader from './ChatbotLoader';

export default function HotelResults({ hotelSearchId }) {
  const [hotels, setHotels] = useState();
  const [sortBy, setSortBy] = useState('popularity');
  const [toShow, setToShow] = useState(10);

  function getHotels(sort = 'popularity') {
    setSortBy(sort);
    return fetch(
      `/api/hotel-results?searchId=${hotelSearchId}&offset=${0}&sortBy=${sort}`,
    )
      .then(res =>
        res.json().then(async result => {
          if (result && result.message === 'invalid') {
            await new Promise(r => setTimeout(r, 1000));
            getHotels();
          }
          if (result && result.hotels) setHotels(result.hotels);
        }),
      )
      .catch(err => {
        console.log(err);
      });
  }

  function handleLoadMore() {
    setToShow(toShow + 10);
  }

  function handleSoryByPrice() {
    setHotels(null);
    getHotels('price');
  }

  function handleSoryByRating() {
    setHotels(null);
    getHotels('guestScore');
  }

  function handleSoryByPopularity() {
    setHotels(null);
    getHotels('popularity');
  }

  useEffect(() => {
    if (!hotels) getHotels();
  }, []);

  if (!hotels) return <ChatbotLoader />;
  if (hotels.length === 0)
    return (
      <>
        I could not find any available hotels for these dates, but you can check
        on{' '}
        <a
          className="tw-underline tw-text-primary-500"
          href="https://www.booking.com/index.html?aid=8014892"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Booking.com
        </a>
        !{' '}
        <a
          className="tw-underline tw-text-primary-500"
          href="https://www.booking.com/index.html?aid=8014892"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Check hotel availability
        </a>
      </>
    );
  return (
    <div>
      Here are some hotels I found. I can also sort by{' '}
      {sortBy === 'popularity' ? (
        <span
          className="tw-text-primary-500 tw-underline cpointer"
          onClick={handleSoryByPrice}
        >
          price
        </span>
      ) : (
        <span
          className="tw-text-primary-500 tw-underline cpointer"
          onClick={handleSoryByPopularity}
        >
          popularity
        </span>
      )}{' '}
      or{' '}
      {sortBy === 'guestScore' ? (
        <span
          className="tw-text-primary-500 tw-underline cpointer"
          onClick={handleSoryByPrice}
        >
          price
        </span>
      ) : (
        <span
          className="tw-text-primary-500 tw-underline cpointer"
          onClick={handleSoryByRating}
        >
          rating
        </span>
      )}
      .
      <ol>
        {hotels.map((hotel, i) => {
          if (i >= toShow) return <></>;
          return (
            <li key={hotel.name}>
              {hotel.name} (US$ {hotel.total}){' '}
              <a
                className="tw-text-primary-500 tw-underline"
                href={hotel.link}
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                Book
              </a>
              <ul>
                <li>Price per night: US$ {hotel.price}</li>
                <li>Room type: {hotel.roomName}</li>
              </ul>
            </li>
          );
        })}
      </ol>
      {toShow < hotels.length && (
        <>
          Looking for more hotels?{' '}
          <span
            className="tw-text-primary-500 hover:tw-text-primary-700 dark-hover:tw-text-primary-300 tw-underline cpointer"
            onClick={handleLoadMore}
          >
            Load more
          </span>
        </>
      )}
    </div>
  );
}
