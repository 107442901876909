import Button from '@mui/material/Button';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { teal } from '@mui/material/colors';

const btnTheme = createTheme({
  palette: {
    primary: {
      main: teal[600],
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      justifyContent: 'left',
    },
  },
});

export default function LoginServiceButton({
  logo,
  link,
  name,
  isLogin,
  onClick,
  isContained,
}) {
  const LoginBtn = (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={btnTheme}>
        <Button
          color="primary"
          variant={isContained ? 'contained' : 'outlined'}
          fullWidth
          size="large"
          onClick={onClick}
        >
          <div className="tw-flex tw-items-stretch tw-w-full">
            {logo}
            <div className="tw-w-full tw-text-center">
              {isLogin ? 'Login' : 'Sign up'} with {name}
            </div>
          </div>
        </Button>
      </ThemeProvider>
    </StyledEngineProvider>
  );
  if (onClick) return LoginBtn;
  return <a href={link}>{LoginBtn}</a>;
}
