import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import Router from 'next/router';
import React from 'react';

const BackButton = () => {
  return (
    <span className="text-light">
      <IconButton
        color="inherit"
        onClick={() => Router.back()}
        edge="start"
        aria-label="Back"
        size="large"
      >
        <BackIcon />
      </IconButton>
    </span>
  );
};

export default BackButton;
