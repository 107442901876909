import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import RoundNavIconBtn from './RoundNavIconBtn';

const MobileGeocoder = dynamic(() => import('./MobileGeocoder'), {
  ssr: false,
});

const MobileGeocoderButton = ({ reducedBtns }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      {(show && (
        <>
          <MobileGeocoder />
          <div id="closebtn">
            <IconButton onClick={toggleShow} aria-label="Close" size="large">
              <CloseIcon className="text-light" />
            </IconButton>
          </div>
        </>
      )) || (
        <div className={reducedBtns ? 'reduced-geocoder' : 'mobile-geocoder'}>
          <RoundNavIconBtn onClick={toggleShow} edge="end" aria-label="Search">
            <SearchIcon />
          </RoundNavIconBtn>
        </div>
      )}
      <style jsx>{`
        #closebtn {
          z-index: 100;
          position: fixed;
          padding-top: 8px;
          top: 0;
          right: 0;
        }
        .mobile-geocoder {
          display: none;
        }
        .reduced-geocoder {
          display: none;
        }
        @media (max-width: 1449px) {
          .mobile-geocoder {
            display: inline;
          }
        }
        @media (max-width: 1449px and min-width: 768px) {
          .reduced-geocoder {
            display: inline;
          }
        }
      `}</style>
    </>
  );
};

export default MobileGeocoderButton;
