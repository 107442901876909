import { gql } from '@apollo/client';

export const START_PASSWORD_RESET = gql`
  mutation startPassWordReset($email: String!, $captcha: String!) {
    startPassWordReset(email: $email, captcha: $captcha) {
      success
      message
    }
  }
`;

export const CHECK_PASSWORD_RESET_TOKEN = gql`
  query checkPassWordResetToken($resetToken: String!) {
    checkPassWordResetToken(resetToken: $resetToken) {
      success
    }
  }
`;

export const CONFIRM_PASSWORD_RESET = gql`
  mutation confirmPassWordReset($resetToken: String!, $password: String!) {
    confirmPassWordReset(resetToken: $resetToken, password: $password) {
      success
      message
    }
  }
`;

export const START_ADD_ACCOUNT_PASSWORD = gql`
  mutation startAddAccountPassword($email: String!, $isNewsletter: Boolean) {
    startAddAccountPassword(email: $email, isNewsletter: $isNewsletter) {
      success
      message
    }
  }
`;

export const CHECK_ADD_ACCOUNT_PASSWORD = gql`
  query checkAddAccountPassword($linkToken: String!, $isNewsletter: Boolean) {
    checkAddAccountPassword(
      linkToken: $linkToken
      isNewsletter: $isNewsletter
    ) {
      success
    }
  }
`;

export const CONFIRM_ADD_ACCOUNT_PASSWORD = gql`
  mutation confirmAddAccountPassword($linkToken: String!, $password: String!) {
    confirmAddAccountPassword(linkToken: $linkToken, password: $password) {
      success
      message
    }
  }
`;

export const IS_EASY_LOGIN = gql`
  query isEasyLogIn {
    isEasyLogIn
  }
`;

export const GET_JOIN_COUNT = gql`
  query getJoinCount {
    getJoinCount {
      day
      week
    }
  }
`;
