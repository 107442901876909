import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { grey, teal } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import capitalize from '../../helpers/capitalize';

const useStyles = makeStyles()((_theme, _params, classes) => ({
  root: {
    boxShadow: '0 -1px 1px -1px rgba(0, 0, 0, 0.2)',
    backgroundColor: teal[800],
  },
  item: {
    color: teal[200],
    [`&.${classes.selected}`]: {
      color: grey[50],
    },
  },
  selected: {},
}));

const SimpleBottomNavigation = props => {
  const { navItems, value, handleChange } = props;

  const { classes } = useStyles();

  return (
    <div className="fixed-bottom w-100 tw-bg-primary-800 bottomnav">
      <BottomNavigation
        className={classes.root}
        value={value}
        onChange={handleChange}
        showLabels
      >
        {navItems.map(navItem => (
          <BottomNavigationAction
            key={navItem.title}
            classes={{
              root: classes.item,
              selected: classes.selected,
            }}
            label={capitalize(navItem.title)}
            icon={
              navItem.muiIconMobile || (
                <FontAwesomeIcon
                  style={{ width: 30, height: 30 }}
                  icon={navItem.icon}
                />
              )
            }
          />
        ))}
      </BottomNavigation>
    </div>
  );
};

export default SimpleBottomNavigation;
