// Source: https://github.com/travelfeed-io/steemconnect.js/blob/master/src/index.js

export default function keychainLogin(username, cb) {
  const signedMessageObj = { type: 'posting', app: 'travelfeed.app' };
  const timestamp = parseInt(new Date().getTime() / 1000, 10);
  const messageObj = {
    signed_message: signedMessageObj,
    authors: [username],
    timestamp,
  };
  window.hive_keychain.requestSignBuffer(
    username,
    JSON.stringify(messageObj),
    'Posting',
    response => {
      if (response.error) return cb(response.error);
      messageObj.signatures = [response.result];
      const token = btoa(JSON.stringify(messageObj));
      return cb(null, token);
    },
  );
}
