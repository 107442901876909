import Avatar from '@mui/material/Avatar';
import { makeStyles } from 'tss-react/mui';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import { imageProxy } from 'tf-post-parser';
import Image from 'components/FutureImage';

const useStyles = makeStyles()(theme => ({
  imgborder: {
    border: `4px solid ${theme.palette.background.default}`,
  },
  innerborder: {
    boxShadow: 'inset 0 0 0 1px hsla(0,0%,0%,.2)',
  },
}));

const ProfileAvatar = props => {
  const { classes } = useStyles();

  const [failed, setFailed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { author, size, withBorder } = props;

  const dimensions = size || 40;

  let imageDimension = { size: 64, name: 'default' };
  if (size > 50) imageDimension = { size: 128, name: 'medium' };
  if (size > 120) imageDimension = { size: 512, name: 'large' };

  const avatarUrl =
    props.authorAvatar && !author
      ? imageProxy(props.authorAvatar, imageDimension.size, imageDimension.size)
      : `/avatar/${author}/${imageDimension.name}`;

  if (failed)
    return (
      <Avatar
        alt={author}
        style={{ width: dimensions, height: dimensions }}
        className={withBorder ? classes.imgborder : classes.innerborder}
      />
    );

  return (
    <Avatar
      alt={author}
      style={
        withBorder
          ? { width: dimensions + 8, height: dimensions + 8 }
          : { width: dimensions, height: dimensions }
      }
      className={withBorder ? classes.imgborder : classes.innerborder}
    >
      <div className={'tw-bg-gray-100 dark:tw-bg-gray-600 w-100 h-100'}>
        <Image
          src={avatarUrl}
          alt={author}
          width={dimensions}
          height={dimensions}
          onError={() => setFailed(true)}
          onLoad={() => setLoaded(true)}
          unoptimized
        />
        {!loaded && (
          <div className={`-tw-pt-[${dimensions}px]`}>
            <Skeleton
              className={withBorder ? classes.imgborder : classes.innerborder}
              variant="circular"
              width={dimensions}
              height={dimensions}
            />
          </div>
        )}
      </div>
    </Avatar>
  );
};

export default ProfileAvatar;
