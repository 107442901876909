import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import LoginServices from '../Login/LoginServices';
// eslint-disable-next-line import/no-cycle
import EasyLoginButton from './EasyLoginButton';
import HrCaption from './HrCaption';
import KeychainLogin from './KeychainLogin';
import LoginServiceButton from '../Login/LoginServiceButton';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoginDialog = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [showReset, setShowReset] = useState(false);
  const [hiveKeychain, setHiveKeychain] = useState(undefined);
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    if (hiveKeychain === undefined && window && window.hive_keychain) {
      setHiveKeychain(true);
    }
  });

  const { open, handleClose } = props;

  return (
    <>
      <Dialog
        maxWidth={hiveKeychain ? 'xs' : 'md'}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '2px',
            }}
          >
            <IconButton onClick={handleClose} aria-label="Close" size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div className="container">
          {hiveKeychain ? (
            <>
              <DialogTitle className="text-center" id="form-dialog-title">
                Log in with Hive Keychain
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To log in with Hive Keychain, please enter your Hive username
                  and click the Keychain button
                </DialogContentText>
                <KeychainLogin setHiveKeychain={setHiveKeychain} />
                <div className="container">
                  <div className="row pt-4">
                    <div className="col-12 pt-2">
                      <HrCaption text="OR LOGIN WITH" />
                    </div>
                  </div>
                </div>
                <div className="text-center pb-3">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setHiveKeychain(false)}
                  >
                    Use a different login method
                  </Button>
                </div>
              </DialogContent>
            </>
          ) : (
            <div className="row justify-content-center">
              {(showEmail || !fullScreen) && (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <DialogTitle className="text-center" id="form-dialog-title">
                    Login with Email
                  </DialogTitle>
                  <DialogContent className="text-center">
                    <EasyLoginButton setShowReset={setShowReset} fullWidth />
                    {showReset && (
                      <>
                        <HrCaption text="Can't log in?" />
                        <div className="pb-3">
                          <Typography
                            color="primary"
                            onClick={props.handleLoginResetSwitch}
                            onKeyPress={props.handleLoginResetSwitch}
                            role="link"
                            tabIndex={0}
                            className="cpointer"
                          >
                            Reset your password
                          </Typography>
                        </div>
                      </>
                    )}
                    {!fullScreen && (
                      <>
                        <HrCaption text="No account?" />
                        <div className="pb-3">
                          <Typography
                            color="primary"
                            onClick={props.switch}
                            onKeyPress={props.switch}
                            role="link"
                            tabIndex={0}
                            className="cpointer"
                          >
                            Create your free account now
                          </Typography>
                        </div>
                      </>
                    )}
                  </DialogContent>
                </div>
              )}
              <div
                className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ${
                  fullScreen && !showEmail ? '' : 'hrright'
                }`}
              >
                <DialogTitle className="text-center" id="form-dialog-title">
                  {fullScreen && !showEmail ? `Login` : 'Or login with'}
                </DialogTitle>
                {fullScreen && !showEmail && (
                  <LoginServiceButton
                    isLogin
                    isContained
                    onClick={() => setShowEmail(true)}
                    logo={
                      <FontAwesomeIcon
                        style={{ width: 25, height: 25 }}
                        icon={faAt}
                        className="textPrimary"
                      />
                    }
                    name="Email"
                  />
                )}
                <div className="pt-2" />
                <LoginServices isLogin />
                {fullScreen && (
                  <>
                    <div className="mt-3" />
                    <HrCaption text="No account?" />
                    <div className="pb-3 text-center">
                      <Typography
                        color="primary"
                        onClick={props.switch}
                        onKeyPress={props.switch}
                        role="link"
                        tabIndex={0}
                        className="cpointer"
                      >
                        Create your free account now
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <style jsx>{`
                .hrright {
                  border-width: 1px;
                  border-style: solid;
                  border-image: linear-gradient(
                      to bottom,
                      transparent,
                      rgba(129, 128, 120, 0.3),
                      transparent
                    )
                    1 100%;
                  border-right-style: none;
                }
              `}</style>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LoginDialog;
