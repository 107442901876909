import urlSafeBase64 from 'helpers/urlSafeBase64';

const imgProxyLoader = ({
  src,
  width,
  // quality,
  ratio,
}) => {
  // Detect (animated) gif, proxy through hiveimages
  const isGif = src.substring(src.length - 3, src.length) === 'gif';
  if (isGif) {
    if (src.substring(0, 25) === 'https://images.hive.blog/') return src;
    return 'https://images.hive.blog/0x0/' + src;
  }

  const isLocal = src.startsWith('/');
  if (isLocal) return src;

  const encodedURL = urlSafeBase64(src);

  let height;
  if (typeof ratio !== 'undefined') {
    height = Math.round(width / ratio);
  }
  return (
    'https://img.truvvle.com/?src=' +
    encodedURL +
    '&width=' +
    width +
    (height ? '&height=' + height : '')
  );
};

module.exports = imgProxyLoader;
