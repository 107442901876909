import Button from '@mui/material/Button';

export default function QuestionSuggestion({ append, recommendation }) {
  const { message, label } = recommendation;
  function handleClick() {
    append({
      content: message,
      role: 'user',
    });
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      className="tw-normal-case tw-ml-2 tw-mt-2"
    >
      {label || message}
    </Button>
  );
}
