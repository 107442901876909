/* eslint-disable import/no-cycle */
// no ssr since current user is essential to determine follow status

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FollowIcon from '@mui/icons-material/PersonAdd';
import UnfollowIcon from '@mui/icons-material/PersonAddDisabled';
import Skeleton from '@mui/material/Skeleton';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FOLLOW } from '../../helpers/graphql/broadcast';
import { GET_IS_FOLLOWED } from '../../helpers/graphql/profile';
import graphQLClient from '../../helpers/graphQLClient';
import { getUser } from '../../helpers/token';
import OpacityBtn from '../General/OpacityBtn';
import LoginButton from '../Header/LoginButton';
import RoundNavIconBtn from '../Header/RoundNavIconBtn';

const FollowButton = props => {
  const { community, opacityBtn } = props;

  const [isFollowed, setFollowed] = useState(undefined);
  const [changing, setChanging] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (getUser()) {
      graphQLClient(GET_IS_FOLLOWED, {
        author: props.author,
        community: props.community,
      }).then(res => {
        setFollowed(res.isFollowed);
      });
    } else setFollowed(false);
  }, [props]);

  const newNotification = notification => {
    if (notification !== undefined) {
      let variant = 'success';
      if (notification.success === false) {
        variant = 'error';
      }
      const { enqueueSnackbar } = props;
      enqueueSnackbar(notification.message, { variant });
    }
  };

  const pastBroadcast = res => {
    setChanging(false);
    if (!res.success) newNotification(res);
    else setFollowed(!isFollowed);
  };

  const callApi = () => {
    const variables = {
      author: props.author,
      community,
      isFollowed,
    };
    graphQLClient(FOLLOW, variables)
      .then(res => {
        if (res && (res.follow || res.unfollow)) {
          pastBroadcast(res.follow || res.unfollow);
        }
      })
      .catch(err => {
        newNotification({
          success: false,
          message:
            err.message === 'Failed to fetch'
              ? 'Network Error. Are you online?'
              : `Could not follow: ${err.message}`,
        });
      });
  };

  const toggleFollowAuthor = () => {
    if (!getUser()) {
      setOpen(true);
      return;
    }
    setChanging(true);
    callApi();
  };

  if (isFollowed === undefined) {
    return (
      <>
        {props.btnstyle === 'default' && (
          <Skeleton
            className="mx-auto"
            variant="rectangular"
            width={70}
            height={28}
          />
        )}
      </>
    );
  }
  let btnclass = 'm-1';
  if (props.btnstyle === 'whiteborder') {
    btnclass = 'm-1 border-light';
  } else if (props.size === 'small') {
    btnclass = 'm-0 pb-0 pt-0';
  }
  const variant = props.btnstyle === 'solid' ? 'contained' : 'outlined';
  const color = props.btnstyle === 'solid' ? 'primary' : 'inherit';
  const size = props.btnstyle === 'solid' ? undefined : 'small';

  let Btn = Button;
  if (opacityBtn) Btn = OpacityBtn;

  return (
    <>
      {open && (
        <LoginButton
          open={open}
          hideButtons
          onClickClose={() => setOpen(false)}
          text=" to follow your favorite authors and communities"
        />
      )}
      {props.btnstyle === 'menuItem' && isFollowed === undefined && (
        <MenuItem />
      )}
      {(props.btnstyle === 'icon' && (
        <>
          <RoundNavIconBtn
            onClick={() => toggleFollowAuthor()}
            aria-label={isFollowed ? 'Unfollow' : 'Follow'}
          >
            {(changing && (
              <CircularProgress
                className="text-light"
                color="inherit"
                size={18}
              />
            )) ||
              (isFollowed && <UnfollowIcon />) || <FollowIcon />}
          </RoundNavIconBtn>
        </>
      )) ||
        (props.btnstyle === 'menuItem' && (
          <MenuItem
            onClick={() => (changing ? undefined : toggleFollowAuthor())}
          >
            <ListItemIcon>
              {(changing && <CircularProgress color="primary" size={24} />) ||
                (isFollowed && <UnfollowIcon fontSize="small" />) || (
                  <FollowIcon fontSize="small" />
                )}
            </ListItemIcon>
            <ListItemText primary={isFollowed ? `Unfollow` : `Follow`} />
          </MenuItem>
        )) || (
          <Btn
            disabled={changing}
            variant={opacityBtn ? undefined : variant}
            size={size}
            color={color}
            onClick={() => toggleFollowAuthor()}
            className={btnclass}
          >
            {isFollowed ? (
              <>
                {size === 'small' ? (
                  'Unfollow'
                ) : (
                  <>
                    <UnfollowIcon />
                    <span className="pl-2">Unfollow</span>
                  </>
                )}
              </>
            ) : (
              <>
                {size === 'small' ? (
                  'Follow'
                ) : (
                  <>
                    <FollowIcon /> <span className="pl-2">Follow</span>
                  </>
                )}
              </>
            )}
            {changing && (
              <CircularProgress color="primary" className="ml-2" size={24} />
            )}
          </Btn>
        )}
    </>
  );
};

FollowButton.defaultProps = {
  btnstyle: 'default',
};

FollowButton.propTypes = {
  author: PropTypes.string.isRequired,
  btnstyle: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(FollowButton);
