/* eslint-disable import/no-cycle */
import { Mutation } from '@apollo/client/react/components';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ACCEPT_TOS, GET_LOGIN_TOKEN } from '../../helpers/graphql/token';
import graphQLClient from '../../helpers/graphQLClient';
import LoginDialog from '../Login/LoginDialog';
import PasswordPicker from '../Onboarding/PasswordPicker';

const EasyLoginButton = props => {
  const [usernameOrEmail, setUsernameOrEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [loginData, setLoginData] = useState(undefined);

  const newNotification = notification => {
    if (notification !== undefined) {
      let variant = 'success';
      if (notification.success === false) {
        variant = 'error';
      }
      const { enqueueSnackbar } = props;
      enqueueSnackbar(notification.message, { variant });
    }
  };

  const handleLogin = () => {
    graphQLClient(GET_LOGIN_TOKEN, {
      usernameOrEmail,
      password,
    })
      .then(({ login }) => {
        if (login.success && login.needsEmailConfirmation) {
          newNotification({
            success: false,
            message: `You need to confirm your email before you can log in`,
          });
        } else if (login.success && !login.hasAcceptedTos) {
          setLoginData(login);
        } else if (login.success && login.hasAcceptedTos) {
          setLoginData(login);
          // Force hard reload on login to update apollo context with new access token
          window.open('/dashboard', '_self', undefined, true);
        } else {
          newNotification({
            success: false,
            message: `The login credentials you provided are not valid. Please log in again.`,
          });
          props.setShowReset(true);
        }
      })
      .catch(() => setLoginData({}));
  };

  const handleUsernameEmailInput = event => {
    setUsernameOrEmail(event.target.value.toLowerCase());
  };

  const handleEasyLogin = () => {
    if (usernameOrEmail && password) handleLogin();
    else
      newNotification({
        success: false,
        message: 'Enter your username and password first',
      });
  };

  const handleEasyKeyPress = event => {
    if (event.key === 'Enter') handleEasyLogin();
  };

  if (loginData) {
    // If tos are not accepted, display tos dialogue
    if (loginData.success && !loginData.hasAcceptedTos) {
      return (
        <Mutation
          mutation={ACCEPT_TOS}
          variables={{
            token: loginData.signUpToken,
          }}
        >
          {(acceptTos, { data: mutatedata }) => {
            // If successful
            if (mutatedata && mutatedata.acceptTos) {
              if (mutatedata.acceptTos && mutatedata.acceptTos.success) {
                window.open('/dashboard', '_self', undefined, true);
                return <CircularProgress />;
              }
              newNotification({
                success: false,
                message: `The login credentials you provided are not valid. Please log in again.`,
              });
            }
            return <LoginDialog acceptTos={acceptTos} />;
          }}
        </Mutation>
      );
    }
    return <CircularProgress />;
  }
  return (
    <>
      <TextField
        variant="standard"
        autoFocus
        onChange={handleUsernameEmailInput}
        margin="dense"
        id="username"
        label="Email or username"
        value={usernameOrEmail}
        fullWidth
        onKeyPress={handleEasyKeyPress}
      />
      <PasswordPicker
        label="Password"
        setPassword={setPassword}
        onKeyPress={handleEasyKeyPress}
      />
      <div className="pt-4 pb-2">
        <Button
          fullWidth={props.fullWidth}
          fullsize
          onClick={handleEasyLogin}
          color="primary"
          variant="contained"
          size="large"
        >
          Login
        </Button>
      </div>
    </>
  );
};

EasyLoginButton.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(EasyLoginButton);
