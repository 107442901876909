import { Mutation } from '@apollo/client/react/components';
import {
  faFacebook,
  faPinterest,
  faReddit,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/AddComment';
import Image from 'components/Image';
import { withSnackbar } from 'notistack';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CONTEST_SOCIAL } from '../../helpers/graphql/contest';
import DbuzzLogo from '../../images/dbuzz.svg';

const PostSocialShares = props => {
  const {
    fullLink,
    author,
    permlink,
    title,
    thumbnail,
    tags,
    enqueueSnackbar,
    comments,
    isCommentMode,
    pinterestImage,
  } = props;

  const newNotification = notification => {
    if (notification !== undefined) {
      let variant = 'success';
      if (notification.success === false) {
        variant = 'error';
      }
      enqueueSnackbar(notification.message, { variant });
    }
  };

  const link = `https://travelfeed.com/${
    fullLink || `@${author}/${isCommentMode ? 'comments/' : ''}${permlink}`
  }`;
  let tagString = '';
  if (tags && tags.length > 0)
    tags.forEach((t, i) => {
      if (i > 0) tagString += ',';
      tagString += t;
    });

  const generatedPinterestImage = `https://tfog.b-cdn.net/api/pinterest?title=${encodeURIComponent(
    title,
  )}&user=${encodeURIComponent(author)}&image=${encodeURIComponent(thumbnail)}`;
  const social = [
    {
      name: 'Facebook',
      color: '#2C64F6',
      link: `https://www.facebook.com/sharer.php?u=${encodeURIComponent(link)}`,
      icon: (
        <FontAwesomeIcon
          style={{ width: '22px', height: '22px' }}
          icon={faFacebook}
          className="textPrimary"
        />
      ),
    },
    {
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        link,
      )}&text=${encodeURIComponent(title)}&hashtags=${encodeURIComponent(
        tagString,
      )}&via=joinTravelFeed`,
      icon: (
        <FontAwesomeIcon
          style={{ width: '22px', height: '22px' }}
          icon={faXTwitter}
        />
      ),
    },

    {
      name: 'D.Buzz',
      color: '#E50123',
      link: `https://d.buzz/#/intent/buzz?url=${encodeURIComponent(
        link,
      )}&text=${encodeURIComponent(title)}&tags=${encodeURIComponent(
        tagString,
      )}`,
      icon: (
        <Image
          src={DbuzzLogo}
          alt="D.Buzz"
          height={22}
          width={22}
          unoptimized
        />
      ),
    },
    {
      name: 'Pinterest',
      color: '#E50123',
      link: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        link,
      )}&media=${encodeURIComponent(
        pinterestImage || generatedPinterestImage,
      )}&description=${encodeURIComponent(title)}`,
      icon: (
        <FontAwesomeIcon
          style={{ width: '22px', height: '22px' }}
          icon={faPinterest}
        />
      ),
    },
    {
      name: 'Reddit',
      color: '#FF4500',
      link: `https://www.reddit.com/submit?title=${encodeURIComponent(
        title,
      )}&url=${encodeURIComponent(link)}`,
      icon: (
        <FontAwesomeIcon
          style={{ width: '22px', height: '22px' }}
          icon={faReddit}
        />
      ),
    },
  ];

  return (
    <>
      <div className="p-1">
        <Mutation mutation={CONTEST_SOCIAL}>
          {(
            contestSocial,
            // eslint-disable-next-line no-shadow
          ) => (
            <div className="text-center">
              {social.map(s => {
                return (
                  <a
                    key={s.name}
                    onClick={contestSocial}
                    href={s.link}
                    title={s.name}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    style={{ color: s.color }}
                  >
                    <IconButton
                      color={s.color ? 'inherit' : 'textPrimary'}
                      aria-label={s.name}
                      size="large"
                    >
                      {s.icon}
                    </IconButton>
                  </a>
                );
              })}
              <CopyToClipboard
                text={link}
                onCopy={() =>
                  newNotification({
                    success: true,
                    message: 'Link copied to your clipboard',
                  })
                }
              >
                <IconButton aria-label="Copy to Clipboard" size="large">
                  <FontAwesomeIcon
                    style={{ width: '20px', height: '20px' }}
                    icon={faLink}
                  />
                </IconButton>
              </CopyToClipboard>
              {comments && (
                <a href="#comments">
                  <IconButton aria-label="View Comments" size="large">
                    <CommentIcon />
                  </IconButton>
                </a>
              )}
            </div>
          )}
        </Mutation>
      </div>
    </>
  );
};

export default withSnackbar(PostSocialShares);
