import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import React from 'react';
import { getUser } from '../../helpers/token';
import ProfileAvatar from '../Profile/ProfileAvatar';

export default function NotificationItem({
  index,
  actor,
  type,
  permlink,
  rootAuthor,
  handleClose,
  // date,
}) {
  const user = getUser();

  let text = '';
  let link = '';
  if (type === 'follow') {
    text = (
      <div>
        <Link href={`/@${actor}`} className="font-weight-bold">
          {actor}
        </Link>{' '}
        followed you
      </div>
    );
    link = `/@${actor}`;
  }
  if (type === 'vote') {
    text = (
      <div>
        <Link href={`/@${actor}`} className="font-weight-bold">
          {actor}
        </Link>{' '}
        gave you a smile
      </div>
    );
    link = `/@${user}/${permlink}`;
  }
  if (type === 'postreply') {
    text = (
      <div>
        <Link href={`/@${actor}`} className="font-weight-bold">
          {actor}
        </Link>{' '}
        replied to your post
      </div>
    );
    link = `/@${user}/${permlink}#comments`;
  }
  if (type === 'commentreply') {
    text = (
      <div>
        <Link href={`/@${actor}`} className="font-weight-bold">
          {actor}
        </Link>{' '}
        replied to your comment
      </div>
    );
    link = `/@${rootAuthor}/${permlink}#comments`;
  }

  if (!text) return <></>;
  return (
    <>
      {index !== 0 && <Divider />}
      <Link
        href={link}
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        tabIndex={index}
      >
        <MenuItem>
          <Link href={`/@${actor}`}>
            <ListItemIcon>
              <ProfileAvatar author={actor} size={30} />
            </ListItemIcon>
          </Link>
          <ListItemText primary={text} />
        </MenuItem>
      </Link>
    </>
  );
}
