import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import shadow from '../../helpers/shadow';

const useStyles = makeStyles()(() => ({
  paper: {
    boxShadow: shadow.xl,
  },
}));

export default function PopupPaper({ children }) {
  const { classes } = useStyles();

  return <Paper className={classes.paper}>{children}</Paper>;
}
