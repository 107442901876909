import Button from '@mui/material/Button';
import { nanoid } from 'nanoid';
import { useState } from 'react';

export default function HotelSearchBtn({
  location,
  checkIn,
  checkOut,
  adultsCount,
  setMessages,
  messages,
}) {
  const [loading, setLoading] = useState(false);

  function getHotels() {
    setLoading(true);
    // console.log(location, checkIn, checkOut, adultsCount);
    const { latitude, longitude } = location;
    return fetch(
      `/api/hotels?checkIn=${checkIn}&checkOut=${checkOut}&adultsCount=${adultsCount}&latitude=${latitude}&longitude=${longitude}`,
    )
      .then(res =>
        res.json().then(result => {
          const newMessages = [
            {
              role: 'user',
              content: 'Start search',
              id: nanoid(),
              createdAt: new Date(),
            },
            {
              role: 'assistant',
              content: '',
              id: nanoid(),
              createdAt: new Date(),
              function_call: {
                name: 'hotelResults',
                arguments: JSON.stringify({ hotelSearchId: result.searchId }),
              },
            },
          ];
          // console.log(newMessages);
          setMessages([...messages, ...newMessages]);
          setLoading(false);
        }),
      )
      .catch(err => {
        console.log(err);
      });
  }
  return (
    <>
      <>
        <Button
          onClick={getHotels}
          variant="contained"
          className="tw-mx-2"
          disabled={loading}
        >
          Start hotel search
        </Button>
      </>
    </>
  );
}
