import InputAdornment from '@mui/material/InputAdornment';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash.throttle';
import Router from 'next/router';
import React from 'react';
import { GMAPS_API_KEY } from '../../config';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function Geocoder(props) {
  const { isMain } = props;

  const isMobile = useMediaQuery('(max-width: 720px)');

  const useStyles = makeStyles()(theme => ({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      width: props.width || '90%',
      height: (props.width && '65px') || '100%',
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      padding: '5px',
      transition: 'width 0.3s',
    },
    inputField: {
      color: 'white',
    },
    inputMainField: {
      color: 'black',
    },
    mobileField: {
      padding: '18px',
    },
    inputMain: {
      width: isMobile ? '320px' : '650px',
      borderRadius: theme.shape.borderRadius,
      // width: props.width || '100%',
      height: '60px',
      backgroundColor: alpha(theme.palette.common.white, 0.65),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.75),
      },
      color: 'black',
      padding: '16px',
      transition: 'width 0.3s',
    },
    inputFocusedMain: {
      // Separate this part into it's own CSS class
      backgroundColor: alpha(theme.palette.common.white, 0.75),
      transition: 'width 0.3s',
    },
    inputFocused: {
      // Separate this part into it's own CSS class
      width: props.width || '120%',
      backgroundColor: alpha(theme.palette.common.white, 0.25),
      transition: 'width 0.3s',
    },
  }));

  const { classes } = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GMAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        request.types = ['(regions)'];
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleSelect = selection => {
    if (!selection || !selection.place_id) return;
    const { place_id, description } = selection;
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId: place_id }, function (results) {
      if (results.length < 1) {
        return;
      }
      const result = results[0];
      // A location box is not exact since countries are usually not
      // rectangular, therefore a query for Germany would result in posts
      // from neighbouring countries to be returned as well. Unlike the
      // subdivision, the country-code is consistant to the database,
      // adding it to the query improves the accuracy
      let countryCode;
      let subdivision;
      let city;
      result.address_components.forEach(adrcomp => {
        adrcomp.types.forEach(t => {
          if (t === 'country') {
            countryCode = adrcomp.short_name.toLowerCase();
          } else if (
            t === 'administrative_area_level_1' ||
            t === 'colloquial_area'
          ) {
            // Manual geocoding overrides for mismatches between Google and Nominatim results
            subdivision = adrcomp.long_name;
            if (subdivision === 'Federal Territory of Kuala Lumpur')
              subdivision = 'Kuala Lumpur';
          } else if (t === 'administrative_area_level_2' || t === 'locality') {
            city = adrcomp.long_name;
            if (city === 'Kuala Lumpur') city = undefined;
            else if (city === 'Beijing') city = undefined;
            else if (city === 'Government of Amsterdam') city = 'Amsterdam';
          }
        });
      });
      const args = `${countryCode ? `&countryCode=${countryCode}` : ''}${
        subdivision ? `&subdivision=${encodeURIComponent(subdivision)}` : ''
      }${city ? `&city=${encodeURIComponent(city)}` : ''}`;
      let { bounds } = result.geometry;
      // Some exact locations have no boundary, so use the less exact viewport
      //  instead
      if (!bounds) {
        bounds = result.geometry.viewport;
      }
      const bound1 = bounds[Object.keys(bounds)[1]];
      const bound2 = bounds[Object.keys(bounds)[0]];
      Router.push(
        `/location?locationBox=${bound1[Object.keys(bound1)[0]]},${
          bound2[Object.keys(bound2)[0]]
        },${bound1[Object.keys(bound1)[1]]},${
          bound2[Object.keys(bound2)[1]]
        }&formatted_address=${description || result.formatted_address}${args}`,
      );
    });
  };

  return (
    <Autocomplete
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => {
        if (x.length === 0)
          return [
            {
              description: 'Melbourne Victoria, Australia',
              id: 'e52b9331affbeac8eaae6da1c168d49708ba7c36',
              matched_substrings: [{ length: 9, offset: 0 }],
              place_id: 'ChIJ90260rVG1moRkM2MIXVWBAQ',
              reference: 'ChIJ90260rVG1moRkM2MIXVWBAQ',
              structured_formatting: {
                main_text: 'Melbourne',
                main_text_matched_substrings: [{ length: 9, offset: 0 }],
                secondary_text: 'Victoria, Australia',
              },
              terms: [
                { offset: 0, value: 'Melbourne' },
                { offset: 10, value: 'Victoria' },
                { offset: 20, value: 'Australia' },
              ],
              types: ['colloquial_area', 'locality', 'political', 'geocode'],
            },
            {
              description: 'Osaka, Japan',
              id: '1dad017c431a4ec8e05d1828042ca922c0080cc8',
              matched_substrings: [{ length: 5, offset: 0 }],
              place_id: 'ChIJ13DMKmvoAGARbVkfgUj_maM',
              reference: 'ChIJ13DMKmvoAGARbVkfgUj_maM',
              structured_formatting: {
                main_text: 'Osaka',
                main_text_matched_substrings: [{ length: 5, offset: 0 }],
                secondary_text: 'Japan',
              },
              terms: [
                { offset: 0, value: 'Osaka' },
                { offset: 7, value: 'Japan' },
              ],
              types: ['administrative_area_level_1', 'political', 'geocode'],
            },
            {
              description: 'New York City, New York, USA',
              id: '7eae6a016a9c6f58e2044573fb8f14227b6e1f96',
              matched_substrings: [{ length: 13, offset: 0 }],
              place_id: 'ChIJOwg_06VPwokRYv534QaPC8g',
              reference: 'ChIJOwg_06VPwokRYv534QaPC8g',
              structured_formatting: {
                main_text: 'New York City',
                main_text_matched_substrings: [{ length: 13, offset: 0 }],
                secondary_text: 'New York, USA',
              },
              terms: [
                { offset: 0, value: 'New York City' },
                { offset: 15, value: 'New York' },
                { offset: 25, value: 'USA' },
              ],
              types: ['locality', 'political', 'geocode'],
            },
            {
              description: 'Bali, Indonesia',
              id: '82f7fa74fa79c2145836610b587ede933432640f',
              matched_substrings: [
                { length: 4, offset: 0 },
                { length: 5, offset: 6 },
              ],
              place_id: 'ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ',
              reference: 'ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ',
              structured_formatting: {
                main_text: 'Bali',
                main_text_matched_substrings: [{ length: 4, offset: 0 }],
                secondary_text: 'Indonesia',
                secondary_text_matched_substrings: [{ length: 5, offset: 0 }],
              },
              terms: [
                { offset: 0, value: 'Bali' },
                { offset: 6, value: 'Indonesia' },
              ],
              types: ['administrative_area_level_1', 'political', 'geocode'],
            },
            {
              description: 'Barcelona, Spain',
              id: '5695851cee37adbcea7305c0473a15906dbcab8f',
              matched_substrings: [{ length: 9, offset: 0 }],
              place_id: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
              reference: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
              structured_formatting: {
                main_text: 'Barcelona',
                main_text_matched_substrings: [{ length: 9, offset: 0 }],
                secondary_text: 'Spain',
              },
              terms: [
                { offset: 0, value: 'Barcelona' },
                { offset: 11, value: 'Spain' },
              ],
              types: ['locality', 'political', 'geocode'],
            },
            {
              description: 'Chiang Mai, Thailand',
              matched_substrings: [
                {
                  length: 10,
                  offset: 0,
                },
              ],
              place_id: 'ChIJQXiZwsjM0DAR1AMlknbhaWo',
              reference: 'ChIJQXiZwsjM0DAR1AMlknbhaWo',
              structured_formatting: {
                main_text: 'Chiang Mai',
                main_text_matched_substrings: [
                  {
                    length: 10,
                    offset: 0,
                  },
                ],
                secondary_text: 'Thailand',
              },
              terms: [
                {
                  offset: 0,
                  value: 'Chiang Mai',
                },
                {
                  offset: 12,
                  value: 'Thailand',
                },
              ],
              types: ['administrative_area_level_1', 'political', 'geocode'],
            },
          ];

        return x;
      }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        handleSelect(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => {
        params.InputProps.disableUnderline = true;
        params.InputProps.startAdornment = (
          <InputAdornment position="end">
            <SearchIcon
              className={`${isMain ? 'text-dark' : 'text-light'} ml-1 mr-3`}
            />
          </InputAdornment>
        );
        params.inputProps.className = `${params.inputProps.className} ${
          isMain ? classes.inputMainField : classes.inputField
        }`;
        let textFieldClass = isMain ? classes.inputMain : classes.input;
        if (props.isMobile) {
          textFieldClass = classes.mobileField;
          params.InputProps.endAdornment = undefined;
        }
        return (
          <TextField
            variant="standard"
            {...params}
            autoFocus={props.autoFocus}
            placeholder={isMain ? 'Where do you want to go?' : 'Search'}
            className={textFieldClass}
            classes={{
              focused: isMain ? classes.inputFocusedMain : classes.inputFocused,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length]),
        );
        return (
          <MenuItem className="cpointer" {...props}>
            <ListItemIcon>
              <LocationOnIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              secondary={option.structured_formatting.secondary_text}
            />
          </MenuItem>
        );
      }}
    />
  );
}
