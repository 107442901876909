import GeoCoder from './Geocoder';

export default function HeaderGeocoder() {
  return (
    <>
      <div
        className="header-geocoder align-self-center"
        style={{ minWidth: 260 }}
      >
        <div className="pl-4 w-100 align-self-center">
          <GeoCoder />
        </div>
      </div>
      <style jsx>{`
        .header-geocoder {
          display: none;
        }
        @media (min-width: 1450px) {
          .header-geocoder {
            display: block;
          }
        }
      `}</style>
    </>
  );
}
