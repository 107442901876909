import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { Add, Create } from '@mui/icons-material';
import DraftIcon from '@mui/icons-material/FileCopy';
import React from 'react';
import Link from '@mui/material/Link';
import PopupPaper from './PopupPaper';
import RoundNavIconBtn from './RoundNavIconBtn';
import AiIcon from '../Icons/AiIcon';

export default function CreateMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Tooltip title="Create">
        <RoundNavIconBtn onClick={handleClick} aria-label="Create">
          <Add />
        </RoundNavIconBtn>
      </Tooltip>
      <Popper id={id} open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <PopupPaper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className="tw-text-left">
                    <Link color="textPrimary" href="/dashboard/publish">
                      <MenuItem>
                        <ListItemIcon>
                          <Create />
                        </ListItemIcon>
                        <ListItemText primary="Create Post" />
                      </MenuItem>
                    </Link>
                    <Link color="textPrimary" href="/dashboard/video">
                      <MenuItem>
                        <ListItemIcon>
                          <FontAwesomeIcon
                            style={{ width: 20, height: 20 }}
                            icon={faVideo}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Create Video" />
                      </MenuItem>
                    </Link>
                    <Link color="textPrimary" href="/dashboard/aiblogger">
                      <MenuItem>
                        <ListItemIcon>
                          <AiIcon />
                        </ListItemIcon>
                        <ListItemText primary="AI Blogger" />
                      </MenuItem>
                    </Link>
                    <Link color="textPrimary" href="/dashboard/drafts">
                      <MenuItem>
                        <ListItemIcon>
                          <DraftIcon />
                        </ListItemIcon>
                        <ListItemText primary="View Drafts" />
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </PopupPaper>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}
