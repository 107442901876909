import React, { useContext } from 'react';
import AIIconImg from '../../images/ai.svg';
import AIIconDarkImg from '../../images/aidark.svg';
import Image from 'components/Image';
import UserContext from '../General/UserContext';

export default function AiIcon({ size }) {
  const { theme } = useContext(UserContext);
  const isDarkTheme = theme === 'dark';
  return (
    <Image
      priority
      alt="Title AI"
      src={isDarkTheme ? AIIconDarkImg : AIIconImg}
      height={size || 20}
      width={size || 20}
    />
  );
}
