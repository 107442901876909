import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

export default function DestinationsNav() {
  return (
    <>
      <Link href="/destinations">
        <Button color="inherit" className="tw-text-gray-200">
          Destinations
        </Button>
      </Link>
      <Link href="/join">
        <Button color="inherit" className="tw-text-gray-200">
          Start your own blog
        </Button>
      </Link>
      <Link href="/map">
        <Button color="inherit" className="tw-text-gray-200">
          Map
        </Button>
      </Link>
      <Link href="/how-to-make-money-with-a-travel-blog">
        <Button color="inherit" className="tw-text-gray-200">
          Earn
        </Button>
      </Link>
    </>
  );
}
