import { useQuery } from '@apollo/client';
import { teal } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { GET_JOIN_COUNT } from '../../helpers/graphql/onboarding';

export default function JoinCount() {
  const useStyles = makeStyles()(() => ({
    joinCountCard: {
      border: '3px solid green',
      borderColor: teal[600],
      borderRadius: 5,
    },
  }));

  const { classes } = useStyles();

  const { data } = useQuery(GET_JOIN_COUNT);

  if (
    !data ||
    !data.getJoinCount ||
    (!data.getJoinCount.day && !data.getJoinCount.week) ||
    (data.getJoinCount.day < 2 && data.getJoinCount.week < 4)
  )
    return <></>;
  return (
    <div className={`p-2 text-center ${classes.joinCountCard}`}>
      <Typography variant="body1">
        {data.getJoinCount.day > 2
          ? data.getJoinCount.day
          : data.getJoinCount.week}{' '}
        new members joined {data.getJoinCount.day > 2 ? 'today' : 'this week'}
      </Typography>
    </div>
  );
}
