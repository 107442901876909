import HotelSearchBtn from './HotelSearchBtn';

export default function SuggestionButton({
  functionCall,
  msgLength,
  messages,
  setMessages,
}) {
  if (
    msgLength === messages.length &&
    functionCall &&
    functionCall.name === 'findHotels'
  ) {
    return (
      <HotelSearchBtn
        {...JSON.parse(functionCall.arguments)}
        setMessages={setMessages}
        messages={messages}
      />
    );
  }
  return <></>;
}
