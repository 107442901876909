import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';

const LegalNotice = () => {
  return (
    <div className="p-2 pt-0">
      <Link color="textPrimary" href="/about">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          About
        </Typography>
      </Link>
      {'　'}
      <Link color="textPrimary" href="/about/faq">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          FAQ
        </Typography>
      </Link>
      {'　'}
      <Link color="textPrimary" href="/about/terms">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          Terms
        </Typography>
      </Link>
      {'　'}
      <Link color="textPrimary" href="/about/privacy">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          Privacy
        </Typography>
      </Link>
      {'　'}
      <Link color="textPrimary" href="/about/affiliate-disclosure">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          Affiliate Disclosure
        </Typography>
      </Link>
      {'　'}
      <Link color="textPrimary" href="/about/cookies">
        <Typography
          className="hoverline"
          color="textSecondary"
          variant="subtitle2"
          display="inline"
        >
          Cookies
        </Typography>
      </Link>
      <div className="pt-2">
        <Typography color="textSecondary" variant="subtitle2" display="inline">
          &copy; {new Date().getFullYear()} TravelFeed - a Hive frontend
        </Typography>
      </div>
    </div>
  );
};

export default LegalNotice;
