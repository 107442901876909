import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react';

const PasswordPicker = props => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const CustomInput = props.customInput || Input;

  return (
    <>
      <FormControl
        fullWidth
        error={!props.isValid}
        variant={props.isOutlined ? 'outlined' : undefined}
      >
        <InputLabel
          htmlFor="adornment-password"
          className={
            props.isOutlined
              ? props.isLightGrayBg
                ? 'tw-bg-[#F4F7FC]'
                : props.isWhiteBg
                ? 'tw-bg-white'
                : 'tw-bg-white dark:tw-bg-[#353F44]'
              : 'tw-pt-3 -tw-ml-3.5'
          }
          size={props.isOutlined ? undefined : 'small'}
        >
          {props.label}
        </InputLabel>
        <CustomInput
          autoFocus={props.autofocus}
          fullWidth
          id="adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={props.password}
          onChange={res => props.setPassword(res.target.value)}
          onKeyPress={props.onKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                color="primary"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={120}
        />
        {props.helper && <FormHelperText>{props.helper}</FormHelperText>}
      </FormControl>
    </>
  );
};

PasswordPicker.defaultProps = {
  isValid: true,
};

export default PasswordPicker;
