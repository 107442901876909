import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/VpnKey';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import JoinDialog from './JoinDialog';
// eslint-disable-next-line import/no-cycle
import LoginDialog from './LoginDialog';
import PasswordResetDialog from './PasswordResetDialog';

const whiteTheme = createTheme({
  palette: {
    primary: {
      main: grey[50],
    },
  },
});

const LoginButton = props => {
  const [open, setOpen] = useState(props.loginOpen);
  const [joinOpen, setJoinOpen] = useState(props.open);
  const [resetOpen, setResetOpen] = useState(props.showResetDialog);

  const handleSwitchDialog = () => {
    setJoinOpen(!joinOpen);
    setOpen(!open);
  };

  const handleLoginResetSwitch = () => {
    setResetOpen(!resetOpen);
    setOpen(!open);
  };

  const handleJoinClickOpen = () => {
    setJoinOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMenuClickOpen = () => {
    props.onClickOpen();
    handleClickOpen();
  };

  const handleJoinMenuClickOpen = () => {
    props.onClickOpen();
    handleJoinClickOpen();
  };

  const handleClose = () => {
    if (props.onClickClose) props.onClickClose();
    setOpen(false);
  };

  const handleJoinClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      if (props.onClickClose) props.onClickClose();
      setJoinOpen(false);
    }
  };

  const handleResetClose = () => {
    if (props.onClickClose) props.onClickClose();
    setResetOpen(false);
  };

  return (
    <>
      {(props.hideButtons && <></>) ||
        (props.isMenu && (
          <>
            <MenuItem onClick={handleJoinMenuClickOpen}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </MenuItem>
            <MenuItem onClick={handleMenuClickOpen}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
          </>
        )) ||
        (props.isList && (
          <>
            <ListItem onClick={handleJoinMenuClickOpen}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </ListItem>
            <ListItem onClick={handleMenuClickOpen}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
          </>
        )) || (
          <>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={whiteTheme}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="ml-1 p-2"
                  onClick={handleJoinClickOpen}
                >
                  Join Now
                </Button>
                {!props.onlySignUp && (
                  <Button
                    color="primary"
                    className="ml-1 p-2"
                    onClick={handleClickOpen}
                  >
                    Login
                  </Button>
                )}
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        )}
      <LoginDialog
        switch={handleSwitchDialog}
        handleLoginResetSwitch={handleLoginResetSwitch}
        open={open}
        handleClose={handleClose}
      />
      <JoinDialog
        switch={handleSwitchDialog}
        open={joinOpen}
        handleClose={handleJoinClose}
        text={props.text}
        usernameToCreate={props.usernameToCreate}
      />
      <PasswordResetDialog
        switch={handleLoginResetSwitch}
        open={resetOpen}
        handleClose={handleResetClose}
        text={props.text}
      />
    </>
  );
};

LoginButton.defaultProps = {
  isMenu: false,
  onClickOpen: undefined,
  onClickClose: undefined,
};

LoginButton.propTypes = {
  isMenu: PropTypes.bool,
  onClickOpen: PropTypes.func,
  onClickClose: PropTypes.func,
};

export default LoginButton;
