import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Cookie from 'js-cookie';
import { nanoid } from 'nanoid';
import Image from 'components/Image';
import Router from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import keychainLogin from '../../helpers/keychain';
import KcLogo from '../../images/keychain.png';

export default function KeychainLogin({ disableAutoFocus }) {
  const [username, setUsername] = useState(undefined);
  const [csrfState, setCsrfState] = useState(undefined);

  useEffect(() => {
    const existingCookie = Cookie.get('csrf_state');
    if (existingCookie) {
      setCsrfState(existingCookie);
    } else {
      const state = nanoid();
      Cookie.set('csrf_state', state);
      setCsrfState(state);
    }
  }, []);

  const handleUsernameInput = () => event => {
    setUsername(event.target.value.toLowerCase().trim());
  };

  const { enqueueSnackbar } = useSnackbar();

  const newNotification = notification => {
    if (notification !== undefined) {
      let variant = 'success';
      if (notification.success === false) {
        variant = 'error';
      }
      enqueueSnackbar(notification.message, { variant });
    }
  };

  const handleLogin = () => {
    keychainLogin(username, (err, token) => {
      if (token) {
        Router.push({
          pathname: '/login/hive',
          query: { access_token: token, state: csrfState },
        });
      }
      if (err) {
        newNotification({
          success: false,
          message: `Could not login: ${err}`,
        });
      }
    });
  };

  const handleKeychainClick = () => {
    if (username && username.length > 2) handleLogin();
    else
      newNotification({
        success: false,
        message: 'Enter your username first',
      });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') handleKeychainClick();
  };

  return (
    <>
      <TextField
        variant="standard"
        autoFocus={!disableAutoFocus}
        value={username}
        onChange={handleUsernameInput()}
        margin="dense"
        id="username"
        label="Hive username"
        fullWidth
        onKeyPress={handleKeyPress}
        inputProps={{
          maxLength: 16,
        }}
      />
      <div className="row pt-4">
        <div className="col-12">
          <Button
            size="large"
            fullWidth
            onClick={handleKeychainClick}
            color="primary"
            variant="contained"
            style={{ height: '40px' }}
          >
            <Image
              src={KcLogo}
              alt="Login with Hive Keychain"
              height={30}
              width={140}
            />
          </Button>
        </div>
      </div>
    </>
  );
}
