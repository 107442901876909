import dayjs from 'dayjs';

export default function ClarifyHotelSearch({ checkIn, checkOut, adultsCount }) {
  return (
    <>
      You would like me to search for available hotels for{' '}
      <strong>{adultsCount}</strong> adult{adultsCount > 1 ? 's' : ''}, with
      check-in on <strong>{dayjs(checkIn).format('dddd, MMMM D')}</strong>, and
      check-out on <strong>{dayjs(checkOut).format('dddd, MMMM D')}</strong>, is
      that right? Please click the button below to start the hotel search. If
      there are any changes you&apos;d like to make, please let me know!
    </>
  );
}
