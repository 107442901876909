import { useQuery } from '@apollo/client';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { Notifications } from '@mui/icons-material';
import React from 'react';
import { GET_NOTIFICATIONS } from '../../helpers/graphql/notifications';
import NotificationItem from './NotificationItem';
import PopupPaper from './PopupPaper';
import RoundNavIconBtn from './RoundNavIconBtn';

export default function NotificationsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { data, refetch } = useQuery(GET_NOTIFICATIONS);

  const handleClick = event => {
    if (!anchorEl && refetch) refetch();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Tooltip title="Notifications">
        <RoundNavIconBtn onClick={handleClick} aria-label="Notifications">
          <Notifications />
        </RoundNavIconBtn>
      </Tooltip>
      <Popper id={id} open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <PopupPaper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{ maxHeight: 500, overflowY: 'auto' }}>
                    {data && data.getNotifications && (
                      <>
                        {data.getNotifications.length > 0 ? (
                          data.getNotifications.map((notification, i) => {
                            return (
                              <NotificationItem
                                key={`${i}-${notification.permlink}`}
                                {...notification}
                                handleClose={handleClose}
                                index={i}
                              />
                            );
                          })
                        ) : (
                          <div className="p-3">No notifications yet.</div>
                        )}
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </PopupPaper>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}
