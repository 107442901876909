import React, { useEffect } from 'react';
import Chatbot from './Chatbot';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { useChat } from 'ai/react';
import { getUser } from 'helpers/token';
import Cookie from 'js-cookie';

export default function ChatbotButton({
  location,
  chatMessage,
  autoOpen,
  // showSpeechBubble,
  chatOpen,
  isLanding,
  largerBottomMargin,
}) {
  const [open, setOpen] = React.useState(false);
  // const [showBubble, setShowBubble] = React.useState(false);
  const [functionCall, setFunctionCall] = React.useState(null);
  const [msgLength, setMsgLength] = React.useState(null);

  const handleClick = () => {
    setOpen(previousOpen => !previousOpen);
    const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 30);
    Cookie.set('chatbot', true, { expires });
  };

  const useChatProps = useChat({
    body: { name: getUser() },
    experimental_onFunctionCall: (messages, funCall) => {
      setMsgLength(messages.length);
      setFunctionCall(funCall);
    },
  });

  // async function bubbleShowTimer() {
  //   // TODO: Animate?
  //   await new Promise(r => setTimeout(r, 5000));
  //   // setShowBubble(true);
  // }

  useEffect(() => {
    if (autoOpen) {
      if (window.innerWidth >= 992) {
        setOpen(true);
      }
    }
    // if (showSpeechBubble && !Cookie.get('chatbot')) bubbleShowTimer();
  }, [location]);

  useEffect(() => {
    if (chatMessage) {
      useChatProps.append({
        content: chatMessage,
        role: 'user',
      });
      setOpen(true);
    }
  }, [chatMessage]);

  useEffect(() => {
    if (chatOpen) setOpen(true);
  }, [chatOpen]);

  return (
    <div
      className={
        'tw-fixed tw-bottom-0 tw-right-0 lg:tw-bottom-4 lg:tw-right-4 ' +
        (open ? 'tw-z-[9999]' : 'tw-z-[999] lg:tw-z-[1001]')
      }
    >
      <div>
        <Chatbot
          open={open}
          location={location}
          setOpen={setOpen}
          {...useChatProps}
          functionCall={functionCall}
          msgLength={msgLength}
          isLanding={isLanding}
        />
      </div>
      <div
        className={
          'safebottom tw-flex tw-justify-end tw-pt-2 tw-pr-3 lg:tw-mb-0 lg:tw-pr-0' +
          (open ? ' tw-absolute lg:tw-relative' : '') +
          (largerBottomMargin ? ' tw-mb-28' : ' tw-mb-16')
        }
      >
        {/* {!open && showBubble && (
          <div
            className="tw-flex tw-group cpointer tw-w-72"
            onClick={handleClick}
          >
            <div className="tw-flex tw-p-2 tw-bg-primary-100 group-hover:tw-bg-primary-200 tw-rounded tw-shadow-lg tw-text-gray-900">
              <div className="tw-m-auto">
                I&apos;m here to help make your trip awesome. How can I help? 😀
              </div>
            </div>
            <div
              className="tw-m-auto tw-w-0 tw-h-0 tw-border-solid
          tw-border-t-[10px] tw-border-t-transparent
          tw-border-l-[15px] tw-border-l-primary-100 group-hover:tw-border-l-primary-200
          tw-border-b-[10px] tw-border-b-transparent tw-border-r-transparent"
            ></div>
          </div>
        )} */}
        <Button
          variant="contained"
          color="primary"
          aria-describedby="Open Chatbot"
          onClick={handleClick}
          aria-label="Close"
          size="large"
          className="tw-text-white tw-rounded-full tw-h-16 tw-w-16"
        >
          {open ? <CloseIcon /> : <ChatIcon />}
        </Button>
      </div>
    </div>
  );
}
