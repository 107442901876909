import Typography from '@mui/material/Typography';
import React from 'react';
import Cookies from '../About/Texts/Cookies';
import Privacy from '../About/Texts/Privacy';
import Terms from '../About/Texts/Terms';

const AllTerms = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Terms of Service
      </Typography>
      <Typography>
        <Terms />
      </Typography>
      <Typography variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography>
        <Privacy />
      </Typography>
      <Typography variant="h3" gutterBottom>
        Cookie Policy
      </Typography>
      <Typography>
        <Cookies />
      </Typography>
    </>
  );
};

export default AllTerms;
