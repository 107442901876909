import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  bgBtn: {
    margin: 3,
    padding: 8,
    background: 'rgba(255, 255, 255, 0.1)',
    color: grey[200],
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
  },
}));

export default function RoundNavIconBtn(props) {
  const { classes } = useStyles();

  return <IconButton {...props} className={classes.bgBtn} size="large" />;
}
