import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default function UserMessage({ message }) {
  const { content, createdAt } = message;
  return (
    <div className="tw-mx-2 tw-mb-4 tw-text-right">
      <div className="tw-font-semibold tw-text-sm">You</div>
      <div className="tw-flex tw-justify-end">
        <div className="tw-py-3 tw-px-4 tw-bg-primary-400 dark:tw-bg-primary-800 tw-rounded-bl-xl tw-rounded-tl-xl tw-rounded-br-xl tw-text-white">
          {content}
        </div>
      </div>
      <div className="tw-text-xs tw-text-gray-600 dark:tw-text-gray-200">
        {dayjs(createdAt).fromNow()}
      </div>
    </div>
  );
}
